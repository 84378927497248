import React from "react";
import {
  Alert,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import PFMInput from "../../components/PFMInput";
import PFMCheckbox from "../../components/PFMCheckbox";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { aChatId, aUserInfo, aWindowState } from "../../core/states";
import { connectRtm, rtmCreateChat, rtmSendChatMessage } from "../../core/rtm";
import Glow from "../../components/glow";
import ContactCard from "../../components/contact_card";

export default function StaticContact() {
  const theme = useTheme();
  const desktop = !useMediaQuery(theme.breakpoints.down("lg"));
  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [busy, setBusy] = useState(false);
  const [windowState, setWindowState] = useRecoilState(aWindowState);
  const emailRegex: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const [userInfo, setUserInfo] = useRecoilState(aUserInfo);
  const [chatId, setChatId] = useRecoilState(aChatId);

  async function startChat() {
    try {
      if (!name || !email || !emailRegex.test(email)) {
        return;
      }
      setSubmitting(true);
      setBusy(true);
      // Connect to the rtm api
      await connectRtm(email);
      // When connected, we start chat.
      const chat = await rtmCreateChat({
        name: name,
        email: email,
        subject: "",
      });
      setChatId(chat.id);
      setUserInfo({ email: email, name: name });
      await rtmSendChatMessage(chat.id, {
        type: "text",
        content: msg,
      });
      setTimeout(() => {
        setWindowState("visible");
      }, 3000);

      enqueueSnackbar("Your message has been sent. ", { variant: "success" });
      setSubmitted(true);
    } catch (err: any) {}
    setBusy(false);
    setSubmitting(false);
  }

  return (
    <div
      id="contact"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
      >
        <Glow size="400px" color="#48F" />
        <svg viewBox="0 0 100 100" style={{ position: "absolute" }}>
          <text
            x={50}
            y={66}
            text-anchor="middle"
            font-size="26"
            fill="none"
            stroke-width=".2"
            stroke="#FFFFFF1A"
            font-family={"Sk-Modernist"}
          >
            Contact
          </text>
        </svg>
        <Typography
          sx={{
            fontSize: desktop ? "48px" : "32px",
            fontWeight: 700,
            fontFamily: "Sk-Modernist",
            lineHeight: "44px",
            marginTop: "70px",
            zIndex: 1,
          }}
        >
          Got a project?{" "}
          <span style={{ color: theme.palette.secondary.light }}>
            Lets Talk
          </span>
        </Typography>
      </Stack>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
      >
        <Typography
          sx={{
            fontSize: desktop ? "20px" : "14px",
            fontWeight: 400,
            maxWidth: desktop ? "700px" : "400px",
            textAlign: "center",
            marginTop: desktop ? "72px" : "32px",
          }}
        >
          Let's Discuss Your Project and Create Something Extraordinary
        </Typography>
      </Stack>

      <Stack
        direction={desktop ? "row" : "column"}
        sx={{ my: "32px", alignSelf: "center" }}
        justifyContent={desktop ? "flex-start" : "center"}
        alignItems={desktop ? "flex-start" : "center"}
        spacing={desktop ? "32px" : "0"}
        rowGap={"24px"}
        flexWrap={"wrap"}
      >
        {/* The side cards (contain contact info ) */}
        <Stack
          flex={1}
          sx={{ mt: "48px", minWidth: desktop ? "600px" : "300px" }}
          spacing={desktop ? "48px" : 0}
          rowGap={"24px"}
        >
          <ContactCard
            title="Email"
            description="Drop us a line! Reach out via email for inquiries, We're here to respond promptly."
            value={
              <a href="mailto:ab@zexware.com" style={{ color: "white" }}>
                ab@zexware.com
              </a>
            }
          />
          <ContactCard
            title="Live chat"
            description="Instant assistance at your fingertips!"
            value={
              <a
                style={{ color: "white", cursor: "pointer" }}
                onClick={() => {
                  setWindowState("visible");
                }}
              >
                Start Livechat
              </a>
            }
          />
          <ContactCard
            title="Phone"
            description="Need to talk? Dial our number for immediate assistance. We're just a call away!"
            value={
              <a href="tel://+923043230634" style={{ color: "white" }}>
                +923043230634
              </a>
            }
          />
          <ContactCard
            title="Submit Form"
            description="Send us a message using this form."
          />
        </Stack>

        {/* The contact form  */}
        <Stack sx={{ px: desktop ? "" : "30px" }}>
          <Stack
            flex={1}
            sx={{
              px: desktop ? "32px" : "24px",
              py: desktop ? "40px" : "28px",
              background: "rgba(255, 255, 255, 0.05)",
              border: "1px solid rgba(255, 255, 255, 0.11)",
              backdropFilter: "blur(1.7999999523162842px)",
              borderRadius: "16px",
              maxWidth: desktop ? "600px" : "400px",
            }}
            spacing={"8px"}
          >
            <PFMInput
              disabled={submitted || submitting}
              label="Full name"
              placeholder="Enter your full name..."
              text={name}
              onUpdate={(t) => setName(t)}
            />
            <PFMInput
              disabled={submitted || submitting}
              label="Email"
              placeholder="Enter your email address..."
              text={email}
              onUpdate={(t) => setEmail(t)}
            />
            <PFMInput
              disabled={submitted || submitting}
              label="Message"
              multiline
              text={msg}
              onUpdate={(t) => setMsg(t)}
              rows={8}
              placeholder="Write your idea."
            />
            <PFMCheckbox
              value={agreement}
              onUpdate={(t) => setAgreement(t)}
              label="By submitting this form, you agree to our friendly privacy policy."
            />
            <SnackbarProvider autoHideDuration={2000}>
              {!submitted && (
                <Button
                  variant="contained"
                  disabled={submitting || !agreement}
                  onClick={startChat}
                >
                  Submit
                </Button>
              )}
            </SnackbarProvider>
            {submitted && <Alert>Your message has been sent.</Alert>}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}
