import React from "react";
import { Stack, Typography } from "@mui/material";
import StaticHeader from "../home/header";
import StaticFooter from "../home/footer";
import DualHeading from "../../components/dual_heading";
import GoToTop from "../../components/fap";
import Livechat from "../../components/chat";

const TERMS = [
  {
    title: "1. Information We Collect",
    text: "",
  },
  {
    title: "a. Firebase Authentication",
    text: "To facilitate user authentication, we utilize Google Firebase with anonymous signin. No personal information is stored or used in this process besides the device identifier.",
  },
  {
    title: "b. Video and Voice Call Integration",
    text: "For seamless video and voice calling, we use Agora SDK. Please review their Privacy Policy for comprehensive information on their data handling practices at agora.io.",
  },
  {
    title: "c. User Data",
    text: "User provided data (such as display name and country) is stored temporarily in our database until the user signs out. The information is deleted automatically and is not stored or processed any further.",
  },
  {
    title: "d. Application Data",
    text: "The application stores essential information on users' device using cache, vital for the application's functionality.",
  },
  {
    title: "2. Data Deletion Requests",
    text: "",
  },
  {
    title: "",
    text: "Anonymous user accounts are automatically deleted when they are no longer signed in. Hence, users do not need to send any data deletion requests for the removal of their provided information. ",
  },

  {
    title: "3. Storage and Cache",
    text: "",
  },
  {
    title: "",
    text: "Our application employs storage and cache, necessary for its functionality. Without this data, users cannot utilize the application.",
  },

  {
    title: "4. Updates and Modifications",
    text: "",
  },
  {
    title: "",
    text: "This Privacy Policy may be periodically updated, and users are encouraged to revisit it regularly to stay informed about any changes.",
  },
  {
    title: "5. Consent",
    text: "",
  },
  {
    title: "",
    text: "By using our application, users acknowledge they have read and understood the terms and privacy policy.",
  },
  {
    title: "6. Additional Provisions",
    text: "",
  },
  {
    title: "a. Security Measures",
    text: "We implement industry-standard security measures to protect user data.",
  },
  {
    title: "b. Third-party Links",
    text: "Our application may contain links to third-party websites. We are not responsible for their privacy practices and encourage users to review their policies.",
  },
  {
    title: "c. Children's Privacy",
    text: "The application is not intended for individuals under the age of 16. We do not knowingly collect data from individuals in this age group.",
  },
];
export default function StrangersPolicy() {
  return (
    <Stack
      flex={1}
      sx={{
        bgcolor: "#030712",
        scrollBehavior: "smooth",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <StaticHeader />

      <Stack flex={1} sx={{ maxWidth: "1280px", my: "120px" }}>
        <DualHeading
          h1="Strangers: "
          h2="Chat and Talk to Strangers"
          sx={{ fontSize: "28px" }}
          h2sx={{ color: "#48F" }}
        />
        <DualHeading
          h1="Privacy"
          h2="Policy"
          sx={{ fontSize: "26px" }}
          h2sx={{ color: "#48F" }}
        />
        <DualHeading
          h1="Last updated: "
          h2="March 24th, 2024"
          sx={{ fontSize: "16px" }}
          h2sx={{ color: "#48F" }}
        />
        <DualHeading
          h1="For further information or inquiries regarding this Privacy Policy, please contact us at "
          h2="ab@zexware.com"
          sx={{ fontSize: "12px" }}
          h2sx={{ color: "#48F" }}
        />
        <br />
        <br />
        <Typography>
          In this Privacy Policy, we outline the information we collect, how it
          is used, and your choices concerning your data for the "Strangers:
          Chat and Talk to Strangers" mobile application, herein referred as
          "Service".
        </Typography>
        <br />
        <Stack spacing={"24px"}>
          {TERMS.map((t) => (
            <>
              {t.title && (
                <Typography
                  sx={{ px: t.text ? "14px" : undefined }}
                  color={"primary"}
                  fontSize={16}
                  fontWeight={500}
                >
                  {t.title}
                </Typography>
              )}
              {t.text && (
                <Typography sx={{ pl: "28px" }} fontSize={16} fontWeight={400}>
                  {t.text}
                </Typography>
              )}
            </>
          ))}
        </Stack>
      </Stack>

      {/* Footer  */}
      <StaticFooter />
      <GoToTop />
      <Livechat />
    </Stack>
  );
}
