import React from "react";
import { CssBaseline, Stack } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    identifier: true;
  }
}
declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    input: true;
  }
}
declare module "@mui/material/Switch" {
  interface SwitchPropsColorOverrides {
    pfm: true;
  }
}

const darkTheme = createTheme({
  breakpoints: {
    values: {
      xl: 1920,
      lg: 1280,
      md: 710,
      sm: 600,
      xs: 0,
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#6740f5",
    },
    info: {
      main: "#7A5AF8",
      "600": "#7A5AF8",
    },
    secondary: {
      main: "#5086F4",
    },
    background: {
      default: "#0C111D",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
  components: {
    MuiRadio: {
      defaultProps: {
        icon: (
          <Stack
            sx={{
              width: "20px",
              height: "20px",
              background: "transparent",
              border: "1px solid #333741",
              borderRadius: "100px",
            }}
          ></Stack>
        ),
        checkedIcon: (
          <Stack
            sx={{
              width: "20px",
              height: "20px",
              background: "white",
              border: "5px solid #7F56D9",
              borderRadius: "100px",
            }}
          ></Stack>
        ),
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          background: "#00000099",
        },
        paperFullScreen: {
          background: "#101828",
        },
      },
    },
    MuiDialogContent: {
      defaultProps: {
        sx: {
          p: 0,
        },
      },
      styleOverrides: {
        root: {
          background: "#101828",
          p: 0,
          m: 0,
          borderRadius: "12px",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          background: "#182230",
          border: "1px solid #FFFFFF1F",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
        },
        containedPrimary: {
          background: "#6740f5",
        },
        containedSecondary: {
          background: "#182230",
          color: "#CECFD2",
          borderRadius: "8px",
          ":hover": {
            background: "#1F242F",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 0,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          height: "44px",
          background: "#182230",
        },
        root: {
          height: "72px",
          background: "#101828",
          "&:nth-of-type(even)": {
            background: "#182230",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          "&.required:after": {
            content: "'*'",
            color: "red",
            padding: "4px",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filled: {
          background: "#182230",
          color: "#CECFD2",
        },
        filledWarning: {
          border: "1px solid #F79009",
          borderRadius: "12px",
        },
        filledInfo: {
          border: "1px solid #079455",
          borderRadius: "12px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        clickable: {
          ":hover": {
            background: "#53389E",
            cursor: "pointer",
          },
        },
      },
      variants: [
        {
          props: {
            variant: "identifier",
          },
          style: {
            background: "#2C1C5F",
            border: "1px solid #53389E",
            borderRadius: "6px",
            color: "#D6BBFB",
            cursor: "default",
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          stroke: "#1F242F",
        },
      },
    },
    MuiIconButton: {
      variants: [
        {
          props: {
            color: "input",
          },
          style: {
            width: "100%",
            height: "100%",
            borderRadius: "6px",
          },
        },
      ],
    },
  },
});

export default function ThemeManager(props: any) {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  );
}
