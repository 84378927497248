import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import GoToTop from "../../components/fap";
import Livechat from "../../components/chat";
import StaticHeader from "../home/header";
import StaticFooter from "../home/footer";
import SectionDivider from "../../components/section_divider";
import PFMInput from "../../components/PFMInput";
import { ArrowOutward, Search } from "@mui/icons-material";
import { BlogPost } from "../../types/Post";
import axios from "axios";
import { AppConfig } from "../../config";
import { enqueueSnackbar } from "notistack";
import moment from "moment";

export default function PageBlog() {
  const theme = useTheme();
  const desktop = !useMediaQuery(theme.breakpoints.down("lg"));
  const [busy, setBusy] = useState(true);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);
  const [data, setData] = useState<
    (BlogPost & {
      authorName: string;
      authorImage: string;
    })[]
  >([]);
  const [search, setSearch] = useState("");

  async function load(page: number) {
    try {
      //Load data
      setBusy(true);
      const posts = await axios.get(
        AppConfig.server.rest + "/blog/?page=" + page
      );
      if (posts.data) {
        setTotalPages(posts.data.pages);
        setPageNumber(posts.data.page);
        setTotalPosts(posts.data.posts);
        setData(posts.data.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load(0);
  }, []);

  function RenderPost(
    post: BlogPost & {
      authorName: string;
      authorImage: string;
    }
  ) {
    return (
      <Stack
        spacing="20px"
        sx={{
          border: "1px solid rgba(255, 255, 255, 0.11)",
          background: "rgba(255, 255, 255, 0.05)",
          borderRadius: "32px",
          p: "28px",
          width: desktop ? "350px" : "90%",
          alignSelf: "center",
        }}
      >
        <img
          src={post.thumbnail}
          style={{ height: "240px", borderRadius: "16px" }}
        />
        <Stack alignItems={"flex-start"} spacing={"24px"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            spacing={"8px"}
            sx={{
              background: "rgba(255, 255, 255, 0.05)",
              p: "4px",
              border: "1.5px solid rgba(255, 255, 255, 0.11)",
              borderRadius: "16px",
            }}
          >
            <Chip variant="outlined" label={post.tags?.[0]} size="small" />
            <Typography fontSize={12} fontWeight={500}>
              8 minute read
            </Typography>
          </Stack>
          <Stack spacing={"8px"}>
            <Link
              sx={{ color: "white", textDecoration: "none" }}
              href={"/blog/" + post.id}
            >
              <Typography fontSize={24} fontWeight={600}>
                {post.title} <ArrowOutward />
              </Typography>
            </Link>
            <Typography fontSize={16} fontWeight={400} color="#D0D5DD">
              {post.excerpt}
            </Typography>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
            <Avatar src={post.authorImage} alt={post.authorName} />
            <Stack spacing={"4px"}>
              <Typography fontSize={14}>{post.authorName}</Typography>
              <Typography fontSize={12} sx={{ opacity: 0.7 }}>
                {moment.unix(post.created).format("DD/MM/YYYY hh:mm A (Z)")}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack sx={{ minHeight: "100vh" }}>
      <StaticHeader />
      <Stack flex={1}>
        <Stack sx={{ pt: "150px" }}>
          <SectionDivider title1="Blog" title2="" />
        </Stack>
        <Stack
          sx={{ mt: "60px" }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <PFMInput
            startIcon={Search}
            fullWidth
            sx={{ width: "400px" }}
            placeholder="Search..."
          />
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={"12px"}
          sx={{
            maxWidth: "1280px",
            width: "100%",
            alignSelf: "center",
            pt: "32px",
          }}
        >
          <Button
            disabled={pageNumber === 0}
            variant="contained"
            color="secondary"
          >
            Previous
          </Button>
          <Typography>
            Page {pageNumber + 1} of {totalPages}
          </Typography>
          <Button
            disabled={pageNumber + 1 === totalPages}
            variant="contained"
            color="secondary"
          >
            Next
          </Button>
        </Stack>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          direction={"row"}
          sx={{
            maxWidth: "1280px",
            alignSelf: "center",
            width: "100%",
            py: "32px",
          }}
          flexWrap={"wrap"}
          rowGap={"18px"}
          columnGap={"18px"}
        >
          {/* Render Blog Post Cards  */}
          {data.map((d) => RenderPost(d))}
        </Stack>
      </Stack>
      <StaticFooter />
      <GoToTop />
      <Livechat />
    </Stack>
  );
}
