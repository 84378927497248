import _ic_bot_dev from "./ic_code.png";
import _ic_web from "./ic_web.png";
import _ic_desktop from "./ic_desktop.png";
import _ic_phone from "./ic_mobile.png";
import _ic_chip from "./ic_chip.png";
import _ic_design from "./ic_design.png";
import _ic_figma from "./ic_figma.png";
import _ic_power from "./ic_power.png";
import _ic_integrations from "./ic_integrations.png";
import _ic_stripe from "./ic_stripe.png";
import _ic_star from "./star.png";
import _ic_avatar from "./avatar.png";
import _ic_gcloud from "./ic_gcloud.png";
import _ic_patreon from "./ic_patreon.png";
import _ic_nowpayments from "./ic_nowpayments.png";
import _ic_chrome from "./ic_chrome.png";
import _ic_livechat from "./ic_livechat.png";
import _ic_web3 from "./ic_web3.png";
import _ic_opensea from "./ic_opensea.png";
import _ic_chat from "./ic_chat.png";
import _ic_x from "./ic_x.png";
import _ic_in from "./ic_in.png";
import _ic_social from "./ic_social.png";
import _ic_trustpilot from "./trustpilot.svg";

export const IC_TRUSTPILOT = _ic_trustpilot;
export const IC_CODING = _ic_bot_dev;
export const IC_WEB = _ic_web;
export const IC_DESKTOP = _ic_desktop;
export const IC_PHONE = _ic_phone;
export const IC_CHIP = _ic_chip;
export const IC_DESIGN = _ic_design;
export const IC_FIGMA = _ic_figma;
export const IC_POWER = _ic_power;
export const IC_AVATAR = _ic_avatar;
export const IC_STAR = _ic_star;
export const IC_INTEGRATIONS = _ic_integrations;
export const IC_STRIPE = _ic_stripe;
export const IC_GCLOUD = _ic_gcloud;
export const IC_PATREON = _ic_patreon;
export const IC_NOWPAYMENTS = _ic_nowpayments;
export const IC_CHROME = _ic_chrome;
export const IC_LIVECHAT = _ic_livechat;
export const IC_WEB3 = _ic_web3;
export const IC_OPENSEA = _ic_opensea;
export const IC_CHAT = _ic_chat;
export const IC_X = _ic_x;
export const IC_IN = _ic_in;
export const IC_SOCIAL = _ic_social;
