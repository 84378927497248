import { Stack, Typography } from "@mui/material";
import React, { CSSProperties, ReactElement } from "react";

export default function Team(props: {
  imgSrc: any;
  heading: string;
  heading1: string;
  name: string;
  colortext: string;
  style?: CSSProperties;
  icons?: any;
}) {
  return (
    <Stack
      spacing={"16px"}
      sx={{
        width: "360px",
        height: "380px",
        backgroundColor: "rgba(255, 255, 255, 0.07)",
        borderRadius: "32px",
        padding: "  30px",
        alignItems: "center",
        color: "#EDEDED",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "24px",
        border: "1px solid rgba(255, 255, 255, 0.15)",
      }}
    >
      {/* {img} */}
      <img
        src={props.imgSrc}
        alt=""
        width={"96px"}
        height={"96px"}
        style={{ borderRadius: "50%" }}
      ></img>

      <Typography
        sx={{
          color: "#FFF",
          fontFamily: "Sk-Modernist",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "700",
        }}
      >
        {props.name}
      </Typography>
      <Stack
        spacing={"10px"}
        sx={{
          color: "#FFF",
          fontSize: "16px",
          fontStyle: "normal",
          alignItems: "center",
          fontWeight: "400",
        }}
      >
        <Typography sx={{ color: "#6D9AFF" }}>{props.colortext}</Typography>
        <Typography sx={{ textAlign: "center" }}>
          {props.heading}
          <p> {props.heading1}</p>
        </Typography>
        {props.icons && (
          <Stack
            direction={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {props.icons}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
