import { RtmClient } from "@zexcore/rtm-client";
import { FbAuth } from "../core/firebase";
import { AppConfig } from "../config";
import { User } from "../types/User";
import { PaginatedResponse } from "../types/IPaginatedResponse";
import { BlogPost } from "../types/Post";

let client: RtmClient;

export async function ensureAuthentication() {
  return new Promise(async (resolve, reject) => {
    if (!client) {
      const token = await FbAuth.currentUser?.getIdToken();
      client = new RtmClient(AppConfig.server.rtm, {
        reconnectDelayMs: 5000,
        onReconnecting(attempt) {
          console.log(`Trying to reconnect for ${attempt} time...`);
        },
        authenticationData: token,
        async onOpen() {
          console.log("Connected to the RTM server.");
          resolve(true);
        },

        onClose() {
          console.log("Connection with the RTM server was closed.");
        },

        onError() {
          console.log("Error connecting to the RTM server");
          reject();
        },
      });
    } else {
      resolve(true);
    }
  });
}

export function getRtmClient() {
  return client;
}

export async function rtmGetProfile() {
  await ensureAuthentication();
  const prof = await client.CallWait<User>("rtmGetProfile");
  return prof;
}

export async function rtmListPosts(props: {
  page: number;
  sort?: {
    type: "title" | "id" | "created" | "updated" | "status";
    value: "desc" | "asc";
  };
  search?: string;
}) {
  await ensureAuthentication();
  const posts = await getRtmClient().CallWait<
    PaginatedResponse<
      BlogPost & {
        authorName: string;
        authorImage: string;
        authorEmail: string;
      }
    >
  >("rtmListPosts", {
    page: props.page,
    sort: props.sort,
    search: props.search,
  });
  return posts;
}

export async function rtmCreatePost(post: Partial<BlogPost>) {
  await ensureAuthentication();
  const prof = await client.CallWait<BlogPost>("rtmCreatePost", post);
  return prof;
}

export async function rtmUpdatePost(post: Partial<BlogPost>) {
  await ensureAuthentication();
  const prof = await client.CallWait<Boolean>("rtmUpdatePost", post);
  return prof;
}

export async function rtmDeletePost(postId: string) {
  await ensureAuthentication();
  const prof = await client.CallWait<Boolean>("rtmDeletePost", postId);
  return prof;
}
