import React from "react";
import {
  Avatar,
  AvatarGroup,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import SHAPE from "../../assets/icon/shape.png";
import { HERO_ILLUSTRATION } from "../../assets";
import {
  ClientAvatar1,
  ClientAvatar2,
  ClientAvatar3,
  ClientAvatar4,
  ClientAvatar5,
  Scribble,
} from "./assets";
import Glow from "../../components/glow";

export default function StaticHero() {
  const theme = useTheme();
  const desktop = !useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <div id="hero" style={{ overflow: "visible" }}>
      <div
        style={{
          position: "absolute",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          zIndex: -10,
          filter: "blur(18px)",
          opacity: 0.6,
          paddingLeft: "100px",
        }}
      >
        <Scribble />
      </div>
      <Stack
        sx={{
          width: "100%",
          paddingTop: 0,
          overflow: "visible",
          justifyContent: "center",
          alignItems: "center",
          [theme.breakpoints.down("lg")]: {
            paddingTop: "32px",
          },
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={desktop ? "space-between" : "center"}
          alignItems={"center"}
          flexWrap={desktop ? "nowrap" : "wrap"}
          sx={{
            marginTop: "100px",
            maxWidth: "1280px",
            overflow: "visible",
            [theme.breakpoints.down("lg")]: {
              marginTop: "120px",
            },
          }}
        >
          <Glow
            size="300px"
            color={theme.palette.secondary.main}
            sx={{ zIndex: -2, top: 0 }}
          />
          {/* The side text  */}
          <Stack
            flex={1}
            sx={{
              position: "relative",
              overflow: "visible",
              px: desktop ? 0 : "24px",
            }}
            spacing={"24px"}
          >
            <Stack
              spacing={"36px"}
              sx={{
                alignItems: "start",
              }}
            >
              <Typography
                sx={{
                  fontSize: desktop ? "40px" : "24px",
                  fontFamily: "Sk-Modernist",
                  fontWeight: 600,
                  letterSpacing: "-2.34px",
                }}
              >
                <span
                  style={{
                    height: desktop ? "204px" : "102px",
                    width: desktop ? "588px" : "250px",
                    background:
                      "linear-gradient(272deg, #7A5AF8 10.3%, #F2F2F2 67%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    fontFamily: "SK-Modernist",
                    letterSpacing: 1,
                  }}
                >
                  {/* <SPARKLE />  */}
                  <img
                    src={SHAPE}
                    alt=""
                    style={{ height: desktop ? "30px" : "20px" }}
                  />{" "}
                  Digital Solutions Tailored to You: App and Web Development
                  Experts
                </span>{" "}
              </Typography>

              <Typography
                sx={{
                  fontSize: desktop ? "16px" : "14px",
                  color: "#D1D5DB",
                  maxWidth: "500px",
                }}
              >
                We offer a comprehensive suite of services designed to meet your
                unique needs. From Desktop, Android, and iOS App development to
                Website Design & Development, we've got you covered.{" "}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"start"}
              rowGap={"8px"}
              columnGap={"8px"}
              flexWrap={"wrap"}
              sx={{ mt: "40px" }}
            >
              <Button
                onClick={() =>
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("contact")?.offsetTop,
                  })
                }
                variant="contained"
                sx={{
                  borderRadius: "12px",
                  height: "48px",
                  alignSelf: "center",
                  transition: "all .3s",
                  outline: "5px solid #1E3A8A00",
                  ":hover": {
                    outline: "5px solid #1E3A8A",
                    background: "#6740f5",
                  },
                }}
              >
                Get a quote -&gt;
              </Button>
              <Button
                onClick={() =>
                  window.scrollTo({
                    behavior: "smooth",
                    top: window.document.getElementById("services")?.offsetTop,
                  })
                }
                variant="text"
                color="secondary"
                sx={{
                  borderRadius: "12px",
                  height: "48px",
                }}
              >
                Learn More
              </Button>
              {/* <Button
            startIcon={
              <SvgIcon sx={{ height: "32px", width: "32px" }}>
                <IC_TRUSTPILOT />
              </SvgIcon>
            }
            sx={{
              height: "48px",
              border: "2px solid #1E3A8A",
              borderRadius: "12px",
              ":hover": {
                border: "2px solid #1E3A8A",
              },
            }}
            href="https://www.trustpilot.com/review/zexware.com"
            variant="outlined"
          >
            Review on Trustpilot
          </Button> */}
            </Stack>

            <Stack
              alignItems={"flex-start"}
              spacing={"0px"}
              sx={{ mt: "60px" }}
            >
              <Typography
                sx={{
                  fontSize: desktop ? "32px" : "24px",
                  fontWeight: 700,
                  lineHeight: "32px",
                }}
              >
                Over{" "}
                <span style={{ color: theme.palette.secondary.light }}>
                  70+
                </span>
              </Typography>
              <Typography
                sx={{
                  mt: "10px",
                  fontSize: desktop ? "16px" : "14px",
                  lineHeight: "32px",
                  fontWeight: 400,
                }}
              >
                Happy Clients
              </Typography>
              <AvatarGroup variant="circular">
                <Avatar
                  sx={{ width: "28px", height: "28px" }}
                  src={ClientAvatar1}
                />
                <Avatar
                  sx={{ width: "28px", height: "28px" }}
                  src={ClientAvatar2}
                />
                <Avatar
                  sx={{ width: "28px", height: "28px" }}
                  src={ClientAvatar3}
                />
                <Avatar
                  sx={{ width: "28px", height: "28px" }}
                  src={ClientAvatar4}
                />
                <Avatar
                  sx={{ width: "28px", height: "28px" }}
                  src={ClientAvatar5}
                />
              </AvatarGroup>
            </Stack>
          </Stack>

          {desktop && (
            <Stack flex={1}>
              <HERO_ILLUSTRATION style={{ width: "650px" }} />
            </Stack>
          )}
        </Stack>
      </Stack>
    </div>
  );
}
