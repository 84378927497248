import { atom } from "recoil";
import { User } from "../types/User";
// chat
const localStorageEffect =
  (key: any) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: any) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const aWindowState = atom<"hidden" | "visible">({
  default: "hidden",
  key: "aWindowState",
  effects: [localStorageEffect("aWindowState")],
});

export const aChatId = atom<string>({
  default: undefined,
  key: "aChatId",
  effects: [localStorageEffect("aChatId")],
});

export const aUserInfo = atom<any>({
  default: undefined,
  key: "aUserInfo",
  effects: [localStorageEffect("aUserInfo")],
});

export const aStatus = atom<"away" | "available">({
  default: "away",
  key: "aStatus",
});

export const aCurrentSection = atom({
  key: "cashify_current_section",
  default: "Dashboard",
});
export const aCurrentSectionDescription = atom({
  key: "cashify_current_section_desc",
  default: "Welcome",
});

export const aProfile = atom<User | null>({
  key: "cashify_profile",
  default: null,
});
