import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ELLIPSE_SMALL, LOGO_HEADER } from "../../assets";
import { useNavigate } from "react-router";
import LinkButton from "../../components/link_button";
import { Close, Menu } from "@mui/icons-material";

export default function StaticHeader(props: { bg?: string }) {
  const desktop = useMediaQuery("screen and (min-width: 710px)");
  const theme = useTheme();
  const [stick, setStick] = useState(false);
  const nav = useNavigate();
  const [toggled, setToggled] = useState(false);

  function handleScroll() {
    setStick(window.scrollY > 400);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <Stack
      sx={{
        bgcolor: props.bg,
        zIndex: 100,
        transition: "all .7s",
        top: 0,
        left: 0,
        right: 0,
        position: stick ? "fixed" : "fixed",
        background: stick ? "#FFFFFF44" : "#FFF0",
        backdropFilter: "blur(6px)",
        alignItems: "center",
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{
          width: "100%",
          maxWidth: "1280px",
          padding: desktop ? "24px 0" : "8px 16px",
        }}
      >
        {/* The logo  */}
        <img
          src={LOGO_HEADER}
          alt="Zexware Logo"
          style={{ width: desktop ? "300px" : "200px", objectFit: "contain" }}
        />

        {/* The navigation menu  */}
        {desktop && (
          <nav>
            <LinkButton label="Home" href="/#" observable="hero" />

            <LinkButton
              observable="services"
              label="Services"
              onClick={() =>
                window.scrollTo({
                  behavior: "smooth",
                  top: window.document.getElementById("services")?.offsetTop,
                })
              }
            />

            <LinkButton
              observable="testimonials"
              label="Testimonials"
              onClick={() =>
                window.scrollTo({
                  behavior: "smooth",
                  top: window.document.getElementById("testimonials")
                    ?.offsetTop,
                })
              }
            />

            <LinkButton
              observable="integrations"
              label="Integrations"
              onClick={() =>
                window.scrollTo({
                  behavior: "smooth",
                  top: window.document.getElementById("integrations")
                    ?.offsetTop,
                })
              }
            />

            <LinkButton
              observable="products"
              label="Products"
              onClick={() =>
                window.scrollTo({
                  behavior: "smooth",
                  top: window.document.getElementById("products")?.offsetTop,
                })
              }
            />
            <LinkButton
              observable="blog"
              label="Blog"
              onClick={() =>
                window.scrollTo({
                  behavior: "smooth",
                  top: window.document.getElementById("blog")?.offsetTop,
                })
              }
            />

            <LinkButton
              observable="team"
              label="Team"
              onClick={() =>
                window.scrollTo({
                  behavior: "smooth",
                  top: window.document.getElementById("team")?.offsetTop,
                })
              }
            />

            <LinkButton
              observable="contact"
              label="Contact"
              onClick={() =>
                window.scrollTo({
                  behavior: "smooth",
                  top: window.document.getElementById("contact")?.offsetTop,
                })
              }
            />
          </nav>
        )}

        {/* The contact button  */}
        {desktop && (
          <Stack>
            <Stack sx={{ height: 0, width: 0 }}>
              <img
                src={ELLIPSE_SMALL}
                style={{
                  position: "absolute",
                  top: 0,
                  marginLeft: desktop ? "-60px" : "-120px",
                  pointerEvents: "none",
                }}
                alt=""
              />
            </Stack>
            <Button
              href="https://store.zexware.com/billing"
              variant="outlined"
              sx={{
                color: "white",
                background: "var(--alpha-black-5, rgba(47, 43, 67, 0.05))",
                borderRadius: "12px",
                padding: "8px 16px",
                borderColor: theme.palette.primary.main,
                ":hover": { color: theme.palette.primary.light },
              }}
            >
              Customer Portal
            </Button>
          </Stack>
        )}

        {!desktop && (
          <IconButton onClick={() => setToggled(!toggled)}>
            {!toggled && <Menu />}
            {toggled && <Close />}
          </IconButton>
        )}
      </Stack>
      {/* The navigation menu  */}
      {toggled && (
        <nav
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <LinkButton label="Home" href="/#" observable="hero" />

          <LinkButton
            observable="services"
            label="Services"
            onClick={() => {
              window.scrollTo({
                behavior: "smooth",
                top: window.document.getElementById("services")?.offsetTop,
              });
              setToggled(false);
            }}
          />

          <LinkButton
            observable="testimonials"
            label="Testimonials"
            onClick={() => {
              setToggled(false);
              window.scrollTo({
                behavior: "smooth",
                top: window.document.getElementById("testimonials")?.offsetTop,
              });
            }}
          />

          <LinkButton
            observable="integrations"
            label="Integrations"
            onClick={() => {
              setToggled(false);
              window.scrollTo({
                behavior: "smooth",
                top: window.document.getElementById("integrations")?.offsetTop,
              });
            }}
          />

          <LinkButton
            observable="products"
            label="Products"
            onClick={() => {
              setToggled(false);
              window.scrollTo({
                behavior: "smooth",
                top: window.document.getElementById("products")?.offsetTop,
              });
            }}
          />

          <LinkButton
            observable="blog"
            label="Blog"
            onClick={() => {
              setToggled(false);
              window.scrollTo({
                behavior: "smooth",
                top: window.document.getElementById("blog")?.offsetTop,
              });
            }}
          />

          <LinkButton
            observable="team"
            label="Team"
            onClick={() => {
              setToggled(false);
              window.scrollTo({
                behavior: "smooth",
                top: window.document.getElementById("team")?.offsetTop,
              });
            }}
          />

          <LinkButton
            observable="contact"
            label="Contact"
            onClick={() => {
              setToggled(false);
              window.scrollTo({
                behavior: "smooth",
                top: window.document.getElementById("contact")?.offsetTop,
              });
            }}
          />
        </nav>
      )}
    </Stack>
  );
}
