import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  Link,
  Stack,
  Typography,
  typographyClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SectionDivider from "../../components/section_divider";
import axios from "axios";
import { AppConfig } from "../../config";
import { BlogPost } from "../../types/Post";
import moment from "moment";
import { LOGO_Z } from "../../assets";
import { ArrowOutward, CallMade } from "@mui/icons-material";

export default function StaticBlog() {
  const theme = useTheme();
  const desktop = !useMediaQuery(theme.breakpoints.down("lg"));
  const [busy, setBusy] = useState(true);
  const [posts, setPosts] = useState<
    (BlogPost & { authorName: string; authorImage: string })[]
  >([]);
  const [errored, setErrored] = useState(false);

  async function load() {
    try {
      const _posts = await axios.get(AppConfig.server.rest + "/blog/recent");
      if (_posts.data.length > 0) {
        setPosts(_posts.data);
      }
      setBusy(false);
    } catch (err: any) {
      setErrored(true);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <div
      id="blog"
      style={{
        maxWidth: "1280px",
        width: "100%",
        alignSelf: "center",
      }}
    >
      <Stack
        sx={{ marginTop: desktop ? "100px" : "100px", width: "100%" }}
        spacing={"32px"}
      >
        <Stack sx={{ py: "100px" }}>
          <SectionDivider title1="Blo" title2="g" />
        </Stack>

        {errored && (
          <Typography textAlign={"center"}>
            Error loading posts. Please reload the page or come back later.{" "}
          </Typography>
        )}

        {posts.length === 0 && (
          <Typography textAlign={"center"}>
            No blog posts available at the moment.
          </Typography>
        )}

        {posts.length > 0 && (
          <Stack
            direction={desktop ? "row" : "column-reverse"}
            spacing="40px"
            sx={{
              mt: desktop ? "160px" : "100px",
              justifyContent: "center",
              alignItems: desktop ? "center" : "flex-start",
              width: desktop ? "100%" : "90%",
              alignSelf: "center",
              border: "1px solid rgba(255, 255, 255, 0.11)",
              background: "rgba(255, 255, 255, 0.05)",
              borderRadius: "32px",
              p: "28px",
            }}
          >
            <Stack flex={1} alignItems={"flex-start"} spacing={"24px"}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={"8px"}
                sx={{
                  background: "rgba(255, 255, 255, 0.05)",
                  p: "4px",
                  border: "1.5px solid rgba(255, 255, 255, 0.11)",
                  borderRadius: "16px",
                }}
              >
                <Chip
                  variant="outlined"
                  label={posts[0].tags?.[0]}
                  size="small"
                />
                <Typography fontSize={12} fontWeight={500}>
                  {posts[0].readingTime} minute read
                </Typography>
              </Stack>
              <Stack spacing={"8px"}>
                <Link
                  sx={{ color: "white", textDecoration: "none" }}
                  href={"/blog/" + posts[0].id}
                >
                  <Typography fontSize={30} fontWeight={600}>
                    {posts[0].title} <ArrowOutward />
                  </Typography>
                </Link>
                <Typography fontSize={16} fontWeight={400} color="#D0D5DD">
                  {posts[0].excerpt}
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                <Avatar src={posts[0].authorImage || LOGO_Z} />
                <Stack spacing={"4px"}>
                  <Typography fontSize={14}>{posts[0].authorName}</Typography>
                  <Typography fontSize={12} sx={{ opacity: 0.7 }}>
                    {moment
                      .unix(posts[0].created)
                      .format("DD/MM/YYYY hh:mm A (Z)")}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <img
              src={posts[0].thumbnail || LOGO_Z}
              style={{
                flex: desktop ? 1 : undefined,
                height: desktop ? "480px" : "200px",
                borderRadius: "16px",
                objectFit: "contain",
              }}
            />
          </Stack>
        )}

        {posts.length > 2 && (
          <Stack direction={desktop ? "row" : "column"} spacing={"32px"}>
            <Stack
              flex={1}
              spacing="20px"
              sx={{
                border: "1px solid rgba(255, 255, 255, 0.11)",
                background: "rgba(255, 255, 255, 0.05)",
                borderRadius: "32px",
                p: "28px",
                width: desktop ? undefined : "90%",
                alignSelf: "center",
              }}
            >
              <img
                src={posts[1].thumbnail}
                style={{
                  height: "480px",
                  borderRadius: "16px",
                  objectFit: "contain",
                }}
              />
              <Stack alignItems={"flex-start"} spacing={"24px"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={"8px"}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05)",
                    p: "4px",
                    border: "1.5px solid rgba(255, 255, 255, 0.11)",
                    borderRadius: "16px",
                  }}
                >
                  <Chip
                    variant="outlined"
                    label={posts[1].tags?.[0]}
                    size="small"
                  />
                  <Typography fontSize={12} fontWeight={500}>
                    {posts[1].readingTime} minute read
                  </Typography>
                </Stack>
                <Stack spacing={"8px"}>
                  <Link
                    sx={{ color: "white", textDecoration: "none" }}
                    href={"/blog/" + posts[1].id}
                  >
                    <Typography fontSize={30} fontWeight={600}>
                      {posts[1].title} <ArrowOutward />
                    </Typography>
                  </Link>
                  <Typography fontSize={16} fontWeight={400} color="#D0D5DD">
                    {posts[1].excerpt}
                  </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                  <Avatar src={posts[1].authorImage} />
                  <Stack spacing={"4px"}>
                    <Typography fontSize={14}>{posts[1].authorName}</Typography>
                    <Typography fontSize={12} sx={{ opacity: 0.7 }}>
                      {moment
                        .unix(posts[1].created)
                        .format("DD/MM/YYYY hh:mm A (Z)")}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              flex={1}
              spacing="20px"
              sx={{
                border: "1px solid rgba(255, 255, 255, 0.11)",
                background: "rgba(255, 255, 255, 0.05)",
                borderRadius: "32px",
                p: "28px",
                width: desktop ? undefined : "90%",
                alignSelf: "center",
              }}
            >
              <img
                src={posts[2].thumbnail}
                style={{
                  height: "480px",
                  borderRadius: "16px",
                  objectFit: "contain",
                }}
              />
              <Stack alignItems={"flex-start"} spacing={"24px"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={"8px"}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05)",
                    p: "4px",
                    border: "1.5px solid rgba(255, 255, 255, 0.11)",
                    borderRadius: "16px",
                  }}
                >
                  <Chip
                    variant="outlined"
                    label={posts[2].tags?.[0]}
                    size="small"
                  />
                  <Typography fontSize={12} fontWeight={500}>
                    {posts[2].readingTime} minute read
                  </Typography>
                </Stack>
                <Stack spacing={"8px"}>
                  <Link
                    sx={{ color: "white", textDecoration: "none" }}
                    href={"/blog/" + posts[2].id}
                  >
                    <Typography fontSize={30} fontWeight={600}>
                      {posts[2].title} <ArrowOutward />
                    </Typography>
                  </Link>
                  <Typography fontSize={16} fontWeight={400} color="#D0D5DD">
                    {posts[2].excerpt}
                  </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                  <Avatar src={posts[2].authorImage} />
                  <Stack spacing={"4px"}>
                    <Typography fontSize={14}>{posts[2].authorName}</Typography>
                    <Typography fontSize={12} sx={{ opacity: 0.7 }}>
                      {moment
                        .unix(posts[2].created)
                        .format("DD/MM/YYYY hh:mm A (Z)")}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}

        {/* {posts.length > 5 && (
          <Stack direction={desktop ? "row" : "column"} spacing={"32px"}>
            <Stack
              flex={1}
              spacing="20px"
              sx={{
                border: "1px solid rgba(255, 255, 255, 0.11)",
                background: "rgba(255, 255, 255, 0.05)",
                borderRadius: "32px",
                p: "28px",
                width: desktop ? undefined : "90%",
                alignSelf: "center",
              }}
            >
              <img
                src={posts[3].thumbnail}
                style={{ height: "240px", borderRadius: "16px" }}
              />
              <Stack alignItems={"flex-start"} spacing={"24px"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={"8px"}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05)",
                    p: "4px",
                    border: "1.5px solid rgba(255, 255, 255, 0.11)",
                    borderRadius: "16px",
                  }}
                >
                  <Chip
                    variant="outlined"
                    label={posts[3].tags?.[0]}
                    size="small"
                  />
                  <Typography fontSize={12} fontWeight={500}>
                    8 minute read
                  </Typography>
                </Stack>
                <Stack spacing={"8px"}>
                  <Link
                    sx={{ color: "white", textDecoration: "none" }}
                    href={"/blog/" + posts[3].id}
                  >
                    <Typography fontSize={24} fontWeight={600}>
                      {posts[3].title}
                    </Typography>
                  </Link>
                  <Typography fontSize={16} fontWeight={400} color="#D0D5DD">
                    {posts[3].excerpt}
                  </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                  <Avatar />
                  <Stack spacing={"4px"}>
                    <Typography fontSize={14}>{posts[3].authorName}</Typography>
                    <Typography fontSize={12} sx={{ opacity: 0.7 }}>
                      {moment
                        .unix(posts[3].created)
                        .format("DD/MM/YYYY hh:mm A (Z)")}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              flex={1}
              spacing="20px"
              sx={{
                border: "1px solid rgba(255, 255, 255, 0.11)",
                background: "rgba(255, 255, 255, 0.05)",
                borderRadius: "32px",
                p: "28px",
                width: desktop ? undefined : "90%",
                alignSelf: "center",
              }}
            >
              <img
                src={posts[4].thumbnail}
                style={{ height: "240px", borderRadius: "16px" }}
              />
              <Stack alignItems={"flex-start"} spacing={"24px"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={"8px"}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05)",
                    p: "4px",
                    border: "1.5px solid rgba(255, 255, 255, 0.11)",
                    borderRadius: "16px",
                  }}
                >
                  <Chip
                    variant="outlined"
                    label={posts[4].tags?.[0]}
                    size="small"
                  />
                  <Typography fontSize={12} fontWeight={500}>
                    8 minute read
                  </Typography>
                </Stack>
                <Stack spacing={"8px"}>
                  <Link
                    sx={{ color: "white", textDecoration: "none" }}
                    href={"/blog/" + posts[4].id}
                  >
                    <Typography fontSize={24} fontWeight={600}>
                      {posts[4].title}
                    </Typography>
                  </Link>
                  <Typography fontSize={16} fontWeight={400} color="#D0D5DD">
                    {posts[4].excerpt}
                  </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                  <Avatar />
                  <Stack spacing={"4px"}>
                    <Typography fontSize={14}>{posts[4].authorName}</Typography>
                    <Typography fontSize={12} sx={{ opacity: 0.7 }}>
                      {moment
                        .unix(posts[4].created)
                        .format("DD/MM/YYYY hh:mm A (Z)")}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack
              flex={1}
              spacing="20px"
              sx={{
                border: "1px solid rgba(255, 255, 255, 0.11)",
                background: "rgba(255, 255, 255, 0.05)",
                borderRadius: "32px",
                p: "28px",
                width: desktop ? undefined : "90%",
                alignSelf: "center",
              }}
            >
              <img
                src={posts[5].thumbnail}
                style={{ height: "240px", borderRadius: "16px" }}
              />
              <Stack alignItems={"flex-start"} spacing={"24px"}>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  spacing={"8px"}
                  sx={{
                    background: "rgba(255, 255, 255, 0.05)",
                    p: "4px",
                    border: "1.5px solid rgba(255, 255, 255, 0.11)",
                    borderRadius: "16px",
                  }}
                >
                  <Chip
                    variant="outlined"
                    label={posts[5].tags?.[0]}
                    size="small"
                  />
                  <Typography fontSize={12} fontWeight={500}>
                    8 minute read
                  </Typography>
                </Stack>
                <Stack spacing={"8px"}>
                  <Link
                    sx={{ color: "white", textDecoration: "none" }}
                    href={"/blog/" + posts[5].id}
                  >
                    <Typography fontSize={24} fontWeight={600}>
                      {posts[5].title}
                    </Typography>
                  </Link>
                  <Typography fontSize={16} fontWeight={400} color="#D0D5DD">
                    {posts[5].excerpt}
                  </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={"8px"}>
                  <Avatar />
                  <Stack spacing={"4px"}>
                    <Typography fontSize={14}>{posts[5].authorName}</Typography>
                    <Typography fontSize={12} sx={{ opacity: 0.7 }}>
                      {moment
                        .unix(posts[5].created)
                        .format("DD/MM/YYYY hh:mm A (Z)")}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )} */}
        <Button
          href="/blog"
          variant="contained"
          sx={{
            borderRadius: "12px",
            height: "48px",
            alignSelf: "center",
            transition: "all .3s",
            background: "#2563EB",
            outline: "5px solid #1E3A8A00",
            ":hover": {
              outline: "5px solid #1E3A8A",
              background: "#2563EB",
            },
          }}
        >
          View All Posts -&gt;
        </Button>
      </Stack>
    </div>
  );
}
