import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { IC_AVATAR, IC_STAR } from "../assets/icon";

export default function TestimonialCard(props: {
  text: string;
  authorName: string;
  authorCountry: string;
}) {
  const desktop = useMediaQuery("screen and (min-width: 710px)");
  const theme = useTheme();
  return (
    <div className="testimonialCard">
      <div
        className="testimonialCardStyle"
        style={{
          width: desktop ? "390px" : "100%",
        }}
      >
        <div className="flex flex-row">
          <img src={IC_STAR} alt="star" />
          <img src={IC_STAR} alt="star" />
          <img src={IC_STAR} alt="star" />
          <img src={IC_STAR} alt="star" />
          <img src={IC_STAR} alt="star" />
        </div>
        <p style={{ fontSize: 12 }}>{props.text}</p>
        <a
          style={{ color: theme.palette.secondary.main }}
          href="https://fiverr.com/zexware"
        >
          Read more on Fiverr
        </a>
        <div className="testimonialAuthor">
          <img src={IC_AVATAR} alt="Avatar" />
          <div>
            <p className="authorName">{props.authorName}</p>
            <p className="authorCountry">{props.authorCountry}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
