import React from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import {
  IC_CHIP,
  IC_CODING,
  IC_DESIGN,
  IC_DESKTOP,
  IC_FIGMA,
  IC_PHONE,
  IC_POWER,
  IC_WEB,
} from "../../assets/icon";
import SectionDivider from "../../components/section_divider";
import ServiceCard from "../../components/service_card";

export default function StaticServices() {
  const theme = useTheme();
  const desktop = !useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <div id="services" style={{ justifyContent: "center", display: "flex" }}>
      <Stack
        sx={{
          mt: desktop ? "250px" : "120px",
          maxWidth: "1280px",
        }}
      >
        <SectionDivider title1="Serv" title2="ices" />
        <Stack
          sx={{ mt: desktop ? "120px" : "64px" }}
          justifyContent={"center"}
          direction={"row"}
          rowGap={"24px"}
          columnGap={"24px"}
          flexWrap={"wrap"}
        >
          <ServiceCard price="$50" heading="Bot Development" imgSrc={IC_CODING}>
            <span>Discord Bots</span>
            <span>WhatsApp Bots</span>
            <span>Telegram Bots</span>
          </ServiceCard>
          <ServiceCard
            heading="Web App Development"
            imgSrc={IC_WEB}
            price="$120"
          >
            <span>React + MUI v5</span>
            <span>NextJS + Tailwindcss</span>
            <span>Widgets</span>
          </ServiceCard>
          <ServiceCard
            heading="Desktop Development"
            imgSrc={IC_DESKTOP}
            price="$70"
          >
            <span>.NET Framework</span>
            <span>ElectronJS + React</span>
            <span>.NET Core</span>
          </ServiceCard>
          <ServiceCard
            heading="Mobile App Development"
            imgSrc={IC_PHONE}
            price="$150"
          >
            <span>ReactNative</span>
            <span>Android Native (Xamarin)</span>
          </ServiceCard>
        </Stack>
        <Stack
          sx={{ mt: "24px" }}
          justifyContent={"center"}
          direction={"row"}
          rowGap={"24px"}
          columnGap={"24px"}
          flexWrap={"wrap"}
        >
          <ServiceCard heading="API Development" imgSrc={IC_CHIP} price="$50">
            <span>NodeJS REST API</span>
            <span>GraphQL API</span>
            <span>WebSockets</span>
          </ServiceCard>
          <ServiceCard
            heading="Web Design & Development"
            imgSrc={IC_DESIGN}
            price="$80"
          >
            <span>WordPress</span>
            <span>Webflow, Carrd</span>
            <span>React / NextJS</span>
          </ServiceCard>
          <ServiceCard
            heading="UI/UX Design"
            imgSrc={IC_FIGMA}
            price="$25/section"
          >
            <span>Web App Design</span>
            <span>Mobile App</span>
            <span>Functional Prototypes</span>
          </ServiceCard>
          <ServiceCard
            heading="Extension Development"
            imgSrc={IC_POWER}
            price="$30"
          >
            <span>Microsoft Edge</span>
            <span>Google Chrome</span>
          </ServiceCard>
        </Stack>
      </Stack>
    </div>
  );
}
