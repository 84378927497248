import axios from "axios";

export async function SendWebhookMessage(
  hook: string,
  title: string,
  text: string,
  color: number,
  fields: { name: string; value: string; inline?: boolean }[]
) {
  try {
    if (Boolean(hook) === false) throw new Error("Invalid webhook address.");
    await axios.post(hook, {
      content: null,
      embeds: [
        {
          title: title,
          description: text,
          color: color,
          fields: fields,
        },
      ],
      username: "Announcely Public API",
      attachments: [],
    });
  } catch (err: any) {}
}

export function calculateReadingTime(content: string): number {
  // Average reading speed (words per minute)
  const wordsPerMinute = 200;

  // Split the content into words and count them
  const wordCount = content.split(/\s+/).length;

  // Calculate the reading time in minutes
  const readingTimeMinutes = wordCount / wordsPerMinute;

  // Extract the integer part of the reading time for the minutes
  let minutes = Math.floor(readingTimeMinutes);

  // Calculate the remaining seconds
  const seconds = Math.round((readingTimeMinutes - minutes) * 60);

  if (seconds > 30) {
    minutes += 1;
  }

  return minutes;
}
