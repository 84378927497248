import React from "react";
import {
  Button,
  Divider,
  IconButton,
  Input,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ELLIPSE_SMALL, LOGO_HEADER } from "../../assets";
import PFMInput from "../../components/PFMInput";
import { Facebook, GitHub, Instagram, Twitter } from "@mui/icons-material";
import Glow from "../../components/glow";
import LinkButton from "../../components/link_button";

export default function StaticFooter() {
  const desktop = useMediaQuery("screen and (min-width: 710px)");
  return (
    <Stack
      sx={{
        pt: "22px",
        background: "rgba(110, 110, 110, 0.03)",
        width: desktop ? "100%" : "100%",
        alignItems: "center",
        border: "1px solid rgba(110, 110, 110, 0.38)",
        borderRadius: "48px 48px 0px 0px",
      }}
    >
      <Stack
        sx={{ maxWidth: desktop ? "1280px" : "auto", position: "relative" }}
      >
        <Stack alignItems={"center"}>
          <Glow size="400px" color="#48F" />
        </Stack>
        {/* <Stack
          direction={desktop ? "row" : "column"}
          justifyContent={desktop ? "space-around" : "space-between"}
          spacing={"100px"}
          sx={{
            display: "none",
            py: desktop ? "80px" : "40px",
            px: desktop ? "120px" : "32px",
            borderRadius: "48px 48px 0px 0px",
          }}
        >
          Newsletter 
          <Stack >
            <Typography fontWeight={600} fontSize={desktop ? 20 : 14}>
              Subscribe to our newsletter
            </Typography>
            <Typography
              sx={{
                maxWidth: desktop ? "500px" : "100%",
                fontSize: desktop ? 16 : 12,
                fontWeight: 400,
              }}
            >
              Get a summary of what we’ve shipped during the last month, behind
              the scenes updates, and team picks.
            </Typography>
          </Stack> 
           <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={desktop ? "center" : "flex-start"}
            spacing="12px"
          >
            <Input
              disableUnderline
              placeholder="Enter your email..."
              sx={{
                background: "white",
                borderRadius: "12px",
                color: "black",
                px: "12px",
                height: "38px",
              }}
            />
            <Button variant="contained">Subscribe</Button>
          </Stack>
        </Stack> */}
        <Stack>
          <Stack
            direction={desktop ? "row" : "column"}
            columnGap={desktop ? "220px" : "40px"}
            rowGap={desktop ? "0px" : "40px"}
            sx={{
              py: "40px",

              // background: "#111827",
              borderRadius: "48px 48px 0px 0px",
            }}
            alignItems={"center"}
            justifyContent={desktop ? "space-around" : "space-around"}
          >
            <img
              src={LOGO_HEADER}
              alt="Zexware Logo"
              style={{ width: "200px", objectFit: "contain" }}
            />
            {desktop && (
              <nav>
                <LinkButton href="/#" label="Home" />

                <LinkButton
                  label="Team"
                  onClick={() =>
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("team")?.offsetTop,
                    })
                  }
                />

                <LinkButton
                  label="Contact"
                  onClick={() =>
                    window.scrollTo({
                      behavior: "smooth",
                      top: window.document.getElementById("contact")?.offsetTop,
                    })
                  }
                />
              </nav>
            )}

            <Stack direction={"row"}>
              <IconButton href="https://facebook.com/zexwareofficial">
                <Facebook />
              </IconButton>
              <IconButton href="https://twitter.com/zexware">
                <Twitter />
              </IconButton>
              <IconButton href="https://github.com/zexware">
                <GitHub />
              </IconButton>
              <IconButton href="https://instagram.com/zexware">
                <Instagram />
              </IconButton>
            </Stack>
          </Stack>
          <Stack
            direction={"row"}
            sx={{
              py: "10px",
              // background: "#111827"
            }}
            alignItems={"center"}
            justifyContent={"space-around"}
            spacing="12px"
          >
            <Typography>
              Copyright &copy; 2023. All rights reserved.{" "}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
