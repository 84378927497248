import React from "react";
import { Stack, SxProps } from "@mui/material";

export default function Glow(props: {
  size: string;
  color: string;
  sx?: SxProps;
}) {
  return (
    <Stack
      sx={{
        height: props.size,
        width: props.size,
        background: props.color,
        borderRadius: "100%",
        filter: "blur(160px)",
        opacity: 0.2,
        position: "absolute",
        ...props.sx,
      }}
    ></Stack>
  );
}
