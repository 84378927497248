import React from "react";
import {
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  TEAM_ANCEKALYAR,
  TEAM_LYNX,
  TEAM_SATTAR,
  TEAM_SHAHID,
} from "../../assets";
import { Facebook, Language, Twitter } from "@mui/icons-material";
import Team from "../../components/team";

export default function StaticTeam() {
  const theme = useTheme();
  const desktop = !useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <div id="team">
      <Stack
        sx={{
          mt: desktop ? "250px" : "120px",
          fontWeight: 700,
        }}
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
      >
        <Typography
          sx={{
            fontFamily: "Sk-Modernist",
            fontSize: desktop ? "68px" : "48px",
            fontWeight: 700,
            lineHeight: "44px",
            marginTop: "70px",
            zIndex: 1,
          }}
        >
          Meet Our{" "}
          <span style={{ color: theme.palette.secondary.light }}>Team</span>
        </Typography>
        <svg viewBox="0 0 10 1.1" style={{ position: "absolute" }}>
          <text
            x="5"
            y="1"
            text-anchor="middle"
            font-size="1"
            fill="none"
            stroke-width=".015"
            stroke="#FFFFFF1A"
            font-family={"Sk-Modernist"}
          >
            Team
          </text>
        </svg>
      </Stack>
      <Stack
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
      >
        <Typography
          sx={{
            fontSize: desktop ? "20px" : "14px",
            fontFamily: "#Sk-Modernist",
            fontWeight: 400,
            maxWidth: desktop ? "700px" : "100%",
            textAlign: "center",
            marginTop: "72px",
          }}
        >
          Dedicated Professionals Committed to Crafting Your Vision
        </Typography>
      </Stack>
      {/* Team */}

      <Stack
        sx={{
          alignItems: "center",
          marginTop: "70px",
          marginBottom: "197px",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            rowGap: "12px",
            columnGap: "12px",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "1280px",
          }}
        >
          <Team
            imgSrc={TEAM_SATTAR}
            name="Abdul Sattar"
            colortext="Sr. Developer"
            heading="React/ReactNative"
            heading1=".NET & NodeJS"
            icons={
              <>
                <IconButton
                  target="_blank"
                  href="https://www.facebook.com/i0x46/"
                >
                  <Facebook />
                </IconButton>
                <IconButton target="_blank" href="https://x.com/i0x46">
                  <Twitter />
                </IconButton>
                <IconButton target="_blank" href="https://sattar.tech">
                  <Language />
                </IconButton>
              </>
            }
          />

          <Team
            imgSrc={TEAM_LYNX}
            name="Ahmad Jamal"
            colortext="Product Designer"
            heading="UI/UX Design with Figma"
            heading1="Wireframing and ProtoTyping"
            icons={
              <>
                <IconButton
                  target="_blank"
                  href="https://www.facebook.com/mrajax01010?mibextid=eHce3h"
                >
                  <Facebook />
                </IconButton>
                <IconButton target="_blank" href="https://jamal.digital">
                  <Language />
                </IconButton>
              </>
            }
          />

          <Team
            imgSrc={TEAM_SHAHID}
            name="Shahid Ali"
            colortext="Jr. Developer"
            heading="UI Design & Development"
            heading1="React/TypeScript"
            icons={
              <>
                <IconButton
                  target="_blank"
                  href="https://www.facebook.com/profile.php?id=100075740662059&mibextid=eHce3h"
                >
                  <Facebook />
                </IconButton>
                <IconButton target="_blank" href="https://x.com/ShahidAli043">
                  <Twitter />
                </IconButton>
                <IconButton
                  target="_blank"
                  href="https://instagram.com/shahidali_solangii"
                >
                  <Language />
                </IconButton>
              </>
            }
          />
          <Team
            imgSrc={TEAM_ANCEKALYAR}
            name="Ance Kalyar"
            colortext="Product Manager"
            heading="Marketing & Sales"
            heading1="Planning & Research"
          />
        </Stack>
      </Stack>
    </div>
  );
}
