import _logo_header from "./zexware/nav-logo.png";
import _ellipse_small from "./art/ellipse_small.png";
import _sparkle from "./art/sparkel.svg";
import _hero_shape_line from "./art/hero_shape_line.svg";
import _hero_glow from "./art/hero_glow.png";
import _hero_illustration from "./art/hero_illustration.svg";
import _glow from "./art/glow.png";
import _bg_dots from "./art/bg_dots.png";
import _logo_z from "./zexware/z.png";
import _team_sattar from "./zexware/team/sattar.png";
import _team_lynx from "./zexware/team/lynx.jpg";
import _team_ancekalyar from "./zexware/team/ancekalyar.jpg";
import _team_shahid from "./zexware/team/shahid.jpg";

// Products
import _prod_cashifybot from "./products/cashifybot.png";
import _prod_announcely from "./products/announcely.png";
import _prod_zexcore from "./products/Zexcore-Dark.png";
import _prod_zexcore_rtm from "./products/Zexcore-Rtm.png";
import _prod_meetup from "./products/Meetup.png";
import _prod_livesupport from "./products/TGLiveSupport.png";
import _prod_buybot from "./products/buy.png";

export const PROD_MEETUP = _prod_meetup;
export const PROD_CASHIFYBOT = _prod_cashifybot;
export const PROD_ANNOUNCELY = _prod_announcely;
export const PROD_ZEXCORE = _prod_zexcore;
export const PROD_ZEXCORE_RTM = _prod_zexcore_rtm;
export const PROD_LIVE_SUPPORT = _prod_livesupport;
export const PROD_BUYBOT = _prod_buybot;

export const LOGO_HEADER = _logo_header;
export const ELLIPSE_SMALL = _ellipse_small;
export const SPARKLE = _sparkle as any;
export const HERO_LINE_SHAPE = _hero_shape_line as any;
export const HERO_GLOW = _hero_glow;
export const HERO_ILLUSTRATION = _hero_illustration as any;
export const GLOW = _glow;
export const BG_DOTS = _bg_dots;
export const LOGO_Z = _logo_z;
export const TEAM_SATTAR = _team_sattar;
export const TEAM_LYNX = _team_lynx;
export const TEAM_ANCEKALYAR = _team_ancekalyar;
export const TEAM_SHAHID = _team_shahid;
