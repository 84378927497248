import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import { enqueueSnackbar } from "notistack";
import PFMCheckbox from "../components/PFMCheckbox";
import { User } from "../types/User";
import { rtmDisableUser } from "../rtm/admin";
import { PersonRemove, Warning } from "@mui/icons-material";

export default function DisableUserDialog(props: DialogProps<User>) {
  const [reason, setReason] = useState("");
  const [notify, setNotify] = useState<boolean>(false);

  const [busy, setBusy] = useState(false);

  async function disableUser() {
    try {
      setBusy(true);
      // Disable user
      await rtmDisableUser(props.data?.uid!);
      enqueueSnackbar(`User ${props.data?.name} was disabled successfully.`, {
        variant: "success",
      });
      props.closeHandler(true);
    } catch (err: any) {
      enqueueSnackbar("Error updating user. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  return (
    <Dialog
      open
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
      fullWidth
    >
      <DialogContent>
        <Stack spacing="20px" sx={{ py: "24px" }}>
          {/* The dialog header  */}
          <Stack
            direction={"row"}
            spacing="16px"
            alignItems={"center"}
            sx={{ px: "24px" }}
          >
            <SvgIcon>
              <PersonRemove />
            </SvgIcon>
            <Stack>
              <Typography fontSize={18} fontWeight={600}>
                Disable User
              </Typography>
              <Typography fontSize={14} fontWeight={400}>
                Temporarily disable user account on firebase.
              </Typography>
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing="16px" sx={{ px: "32px", pb: "32px" }}>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                User's Full Name
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.name}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Email
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.email}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Current Role
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.role}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={400}
                sx={{ minWidth: "150px" }}
              >
                Comments
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                {props.data?.comments || "N/A"}
              </Typography>
            </Stack>
            <Stack direction={"row"} sx={{ width: "100%" }}>
              <Typography
                fontSize={14}
                fontWeight={500}
                sx={{ minWidth: "150px" }}
              >
                Reasons
              </Typography>
              <Stack sx={{ width: "100%" }}>
                <PFMInput
                  onUpdate={(t) => setReason(t)}
                  multiline
                  disabled={busy}
                  text={reason}
                  rows={4}
                  fullWidth
                  placeholder="Explain why this user is being disabled. You can choose to inform the user as well by checking the box below."
                />
              </Stack>
            </Stack>

            <Alert color="warning" variant="filled" icon={<Warning />}>
              <AlertTitle>Warning</AlertTitle>
              <Typography>
                The user will no longer be able to sign in or use their account.
              </Typography>
            </Alert>
          </Stack>
          <Divider />
          <Stack
            direction={"row"}
            sx={{ width: "100%", px: "32px" }}
            spacing="12px"
          >
            <Button
              disabled={busy}
              onClick={props.closeHandler}
              size="large"
              fullWidth
              variant="contained"
              color="secondary"
            >
              No, Cancel
            </Button>
            <Button
              onClick={disableUser}
              disabled={busy}
              size="large"
              fullWidth
              variant="contained"
              color="error"
            >
              Yes, Disable User
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
