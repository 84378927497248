import React from "react";
import { BG_DOTS } from "../../assets";
import {
  IC_CHROME,
  IC_GCLOUD,
  IC_LIVECHAT,
  IC_NOWPAYMENTS,
  IC_OPENSEA,
  IC_PATREON,
  IC_STRIPE,
  IC_WEB3,
} from "../../assets/icon";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import SectionDivider from "../../components/section_divider";
import IntegrationCard from "../../components/integrationscard";

export default function StaticIntegrations() {
  const theme = useTheme();
  const desktop = !useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <div id="integrations">
      <Stack sx={{ marginTop: desktop ? "250px" : "120px" }}>
        <Typography>
          <SectionDivider title1="Integr" title2="ations" />
        </Typography>

        <Stack
          className="gradient-border-container"
          sx={{
            alignSelf: "center",
            mt: desktop ? "160px" : "100px",
            justifyContent: "center",
            alignItems: "center",
            mx: desktop ? 0 : "",
            width: desktop ? "auto" : "90%",
          }}
        >
          <Stack
            sx={{
              borderRadius: "32px",
              alignSelf: "center",
              p: "32px",
            }}
            justifyContent={"center"}
            alignItems="center"
          >
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              alignSelf={"center"}
              direction={"row"}
              rowGap={"24px"}
              columnGap={"24px"}
              flexWrap={"wrap"}
            >
              <IntegrationCard heading="Stripe" imgSrc={IC_STRIPE}>
                <span>Stripe Subscriptions</span>
                <span>Invoices</span>
                <span>Payment Wall</span>
              </IntegrationCard>
              <IntegrationCard heading="Google Cloud" imgSrc={IC_GCLOUD}>
                <span>Firebase Authentication</span>
                <span>Firebase Firestore & FCM</span>
                <span>Google Client SDKs</span>
              </IntegrationCard>
              <IntegrationCard heading="Email APIs" imgSrc={IC_PATREON}>
                <span>Mail Gun</span>
                <span>Mailer Send</span>
                <span>Mailer Lite</span>
              </IntegrationCard>
              <IntegrationCard heading="AI & LLM" imgSrc={IC_OPENSEA}>
                <span>Open AI API</span>
                <span>Pinecone Api </span>
                <span>Lang Chain Api </span>
              </IntegrationCard>
            </Stack>
            <Stack
              sx={{ mt: "24px" }}
              justifyContent={"center"}
              direction={"row"}
              rowGap={"24px"}
              columnGap={"24px"}
              flexWrap={"wrap"}
            >
              <IntegrationCard heading="Nowpayments" imgSrc={IC_NOWPAYMENTS}>
                <span>Accept 100+ Crypto </span>
                <span>Currencies</span>
                <span>Invoices & Subscriptions</span>
              </IntegrationCard>
              <IntegrationCard heading="Puppeteer" imgSrc={IC_CHROME}>
                <span>Website Automation</span>
                <span>Automated Testing</span>
              </IntegrationCard>
              <IntegrationCard heading="Livechat.com" imgSrc={IC_LIVECHAT}>
                <span>Agent Chat API</span>
                <span>Customer SDK </span>
                <span>Widget Development</span>
              </IntegrationCard>
              <IntegrationCard heading="Web3" imgSrc={IC_WEB3}>
                <span>Web3.js in React/NextJS</span>
                <span>Ethers in NodeJS</span>
                <span>Smart Contracts (Solidity)</span>
              </IntegrationCard>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
}
