import React from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  PROD_ANNOUNCELY,
  PROD_BUYBOT,
  PROD_CASHIFYBOT,
  PROD_LIVE_SUPPORT,
  PROD_MEETUP,
  PROD_ZEXCORE,
  PROD_ZEXCORE_RTM,
} from "../../assets";
import Product from "../../components/product";

export default function StaticProducts() {
  const theme = useTheme();
  const desktop = !useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <div id="products">
      <Stack
        sx={{
          fontWeight: 700,
          mt: desktop ? "250px" : "120px",
        }}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography
          sx={{
            fontFamily: "Sk-Modernist",
            fontSize: desktop ? "48px" : "28px",
            fontWeight: 700,
          }}
        >
          Checkout our{" "}
          <span style={{ color: theme.palette.secondary.light }}>Products</span>
        </Typography>
        <Typography
          sx={{
            fontSize: desktop ? "20px" : "14px",
            fontFamily: "#Sk-Modernist",
            fontWeight: 400,
            maxWidth: desktop ? "700px" : "100%",
            textAlign: "center",
          }}
        >
          Built with the the latest technologies available to date.
        </Typography>
      </Stack>

      <Stack
        sx={{
          alignItems: "center",
          marginTop: "70px",
        }}
      >
        <Stack
          direction={"row"}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            rowGap: "12px",
            maxWidth: "1280px",
            columnGap: "12px",
            justifyContent: "center",
            px: "12px",
          }}
        >
          <Product
            logo={PROD_CASHIFYBOT}
            primaryButtonText="Visit Website"
            primaryButtonUrl="https://cashifybot.com/"
            name="Cashifybot"
            color="#48F"
            description="Telegram subscriptions bot powered by Stripe and Cryptochill. Easily monetize your telegram channels and groups with Cashifybot and start accepting credit card/crypto payments. Click to learn more."
          />

          <Product
            logo={PROD_ANNOUNCELY}
            primaryButtonText="Visit Website"
            primaryButtonUrl="https://announcely.app/"
            name="Announcely"
            color="#48F"
            description="Grow your Discord community to Telegram with Announcely. Easily set up message forwarding from your Discord channels to your Telegram channels / groups and engage with your audience with Multi-Platform Announcements. "
          />

          <Product
            logo={PROD_LIVE_SUPPORT}
            primaryButtonText="Subscribe $14.99/mo"
            primaryButtonUrl="https://t.me/TgLiveSupportBuyBot"
            name="Telegram Live Support Bot"
            color="#48F"
            description="Live support bot for telegram designed for businesses that require customer service via Telegram. Supports multiple-agents and is integrated with OpenAI Assistant API."
          />

          <Product
            logo={PROD_BUYBOT}
            primaryButtonText="Buy $50.00"
            primaryButtonUrl="https://store.zexware.com/buy/d5653362-efc2-4e4c-8c86-174dcf4b31fc"
            name="Telegram Buy Bot"
            color="#48F"
            description="The bot allows you to sell your Stripe subscription via Telegram. Users can subscribe to your Stripe subscriptions, see their subscription details, and manage their subscriptions from Telegram."
          />

          <Product
            logo={PROD_ZEXCORE}
            primaryButtonText="Subscribe $14.99/mo"
            primaryButtonUrl="https://store.zexware.com/buy/6ad1f56e-604b-4d03-a1db-6608e226bdfd"
            name="Zexcore Analytics"
            color="#48F"
            description="Real-time Analytics for your Desktop, Web, and Mobile Applications built with the NodeJS framework. Zexcore allows you to collect and monitor analytics from your apps in real-time, with logs, events, and counters."
          />

          <Product
            logo={PROD_ZEXCORE_RTM}
            primaryButtonText="Subscribe $12.99/mo"
            primaryButtonUrl="https://store.zexware.com/buy/767cf43b-8d3e-487a-ad60-6476fa8d98f6"
            name="Zexcore RTM SDK"
            color="#48F"
            description="Real-time Messaging SDK built on the WebSockets API. Provides scalable asynchronous remote-procedures functionality powered by Redis to your NodeJS applications."
          />

          <Product
            logo={PROD_MEETUP}
            primaryButtonText="Visit Website"
            primaryButtonUrl="https://meet.zexware.com/"
            name="MeetUp - Voice & Video Calls"
            color="#48F"
            description="Real-time voice & video calls for everyone. Try MeetUp for your next online meeting, for free."
          />
        </Stack>
      </Stack>
    </div>
  );
}
