import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material";
import { DialogProps } from "./props";
import { TransparentPaper } from "../components/TransparentPaper";
import PFMInput from "../components/PFMInput";
import { enqueueSnackbar } from "notistack";
import {
  Add,
  ArrowDropDown,
  Close,
  ContentCopy,
  Edit,
  PersonAdd,
} from "@mui/icons-material";
import { BlogPost } from "../types/Post";
import MDEditor from "@uiw/react-md-editor";
import { LOGO_Z } from "../assets";
import { rtmCreatePost, rtmDeletePost, rtmUpdatePost } from "../rtm";
import { FbStorage } from "../core/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { calculateReadingTime } from "../core/helper";

export default function EditPostDialog(props: DialogProps<BlogPost>) {
  const [postId, setPostId] = useState<string>();
  const [title, setTitle] = useState("");
  const [excerpt, setExcerpt] = useState("");
  const [permalink, setPermalink] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [thumbnail, setThumbnail] = useState("");
  const [content, setContent] = useState("");
  const [status, setStatus] = useState<"draft" | "published">();

  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [busy, setBusy] = useState(false);

  const [open, setOpen] = React.useState(false);

  const [uploadInfo, setUploadInfo] = useState<string>();
  const [uploadState, setUploadState] = useState<
    "uploading" | "uploaded" | "error"
  >();
  const [uploadProgress, setUploadProgress] = useState(0);

  async function save(status: "draft" | "published") {
    try {
      if (!title || !excerpt || !content) {
        enqueueSnackbar("Missing required fields. ", { variant: "error" });
        return;
      }
      setBusy(true);
      if (postId) {
        // Update
        const r = await rtmUpdatePost({
          id: postId,
          content: content,
          excerpt: excerpt,
          parmalink: permalink,
          status: status,
          tags: tags,
          thumbnail: thumbnail,
          title: title,
          readingTime: calculateReadingTime(content),
        });
        if (r) {
          enqueueSnackbar("The post has been saved. ", { variant: "success" });
          setStatus(status);
        } else {
          throw new Error("Invalid response from server. ");
        }
      } else {
        const r = await rtmCreatePost({
          content: content,
          excerpt: excerpt,
          parmalink: permalink,
          status: status,
          tags: tags,
          thumbnail: thumbnail,
          title: title,
        });
        if (r) {
          enqueueSnackbar("The post has been saved. ", { variant: "success" });
          setStatus(status);
          setPostId(r.id);
        } else {
          throw new Error("Invalid response from server. ");
        }
      }
    } catch (err: any) {
      enqueueSnackbar("Error creating a post. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  async function deletePost() {
    try {
      setBusy(true);
      // Update
      await rtmDeletePost(postId!);
      enqueueSnackbar("The post has been deleted. ", { variant: "success" });
      props.closeHandler();
    } catch (err: any) {
      enqueueSnackbar("Error deleting post. Error: " + err.message, {
        variant: "error",
      });
      console.log(err);
    }
    setBusy(false);
  }

  async function load() {
    if (props.data) {
      setTitle(props.data.title);
      setExcerpt(props.data.excerpt);
      setPostId(props.data.id!);
      setPermalink(props.data.parmalink!);
      setTags(props.data.tags || []);
      setThumbnail(props.data.thumbnail || "");
      setContent(props.data.content || "");
      setStatus(props.data.status);
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  async function uploadPhoto(attachment: File) {
    try {
      setBusy(true);
      const r = ref(FbStorage, `/media/${attachment.name}`);
      enqueueSnackbar("Uploading file..");
      const task = uploadBytesResumable(r, await attachment!.arrayBuffer(), {});
      task.on("state_changed", (snap) => {
        setUploadState("uploading");
        setUploadProgress((snap.bytesTransferred / snap.totalBytes) * 100);
      });
      task.then(async (t) => {
        if (t.state === "error") {
          setUploadState("error");
        } else if (t.state === "success") {
          const url = await getDownloadURL(task.snapshot.ref);
          setUploadState("uploaded");
          setUploadInfo(url);
          // Here we update course
          setThumbnail(url);
          enqueueSnackbar("Cover photo updated successfully.", {
            variant: "success",
          });
        }
        setBusy(false);
      });
    } catch (err: any) {
      enqueueSnackbar("Error uploading file. ", { variant: "error" });
      console.log(err);
    }
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog
      open
      fullScreen
      hideBackdrop
      onClose={props.closeHandler}
      PaperComponent={TransparentPaper}
    >
      <DialogTitle sx={{ width: "100%" }}>
        {/* The dialog header  */}
        <Stack
          direction={"row"}
          spacing="16px"
          alignItems={"center"}
          sx={{ px: "24px", width: "100%" }}
        >
          <SvgIcon>
            {!postId && <Add />}
            {postId && <Edit />}
          </SvgIcon>
          <Stack flex={1}>
            <Typography fontSize={18} fontWeight={600}>
              {postId ? "Edit Post" : "New Post"}
            </Typography>
            <TextField
              required
              disabled={busy}
              value={title}
              onChange={(e) => setTitle(e.currentTarget.value)}
              variant="standard"
              fullWidth
              placeholder="Write a title..."
            />
          </Stack>
          <IconButton onClick={props.closeHandler}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "row" }}>
        <Stack flex={1}>
          <MDEditor
            value={content}
            onChange={(v, e) => setContent(v!)}
            style={{
              flex: 1,
              background: "#222",
              borderRadius: "8px",
              overflow: "hidden",
            }}
            previewOptions={{
              style: {
                background: "#222",
              },
            }}
          />
        </Stack>
        <Stack spacing={"8px"} sx={{ px: "14px", overflow: "auto" }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ width: "100%", p: "24px" }}
            spacing="12px"
          >
            {status && (
              <Chip
                variant="outlined"
                label={status}
                color={status === "draft" ? "default" : "success"}
                sx={{ textTransform: "capitalize" }}
              />
            )}
            <ButtonGroup
              disabled={busy}
              variant="contained"
              size="large"
              ref={anchorRef}
              aria-label="Button group with a nested menu"
            >
              <Button onClick={() => save("published")}>Publish</Button>
              <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
              >
                <ArrowDropDown />
              </Button>
            </ButtonGroup>
            <Popper
              sx={{
                zIndex: 1,
              }}
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu" autoFocusItem>
                        {postId && (
                          <MenuItem onClick={deletePost} key={"delete"}>
                            <Typography color={"error"}>Delete</Typography>
                          </MenuItem>
                        )}
                        <MenuItem onClick={() => save("draft")} key={"draft"}>
                          Save Draft
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Stack>
          <PFMInput
            sx={{ height: "100px" }}
            label="Excerpt"
            important
            text={excerpt}
            onUpdate={(t) => setExcerpt(t)}
            placeholder="Write a short description..."
            helperText="The excerpt text will be shown in the blog post's card. "
          />
          <PFMInput
            sx={{ height: "100px" }}
            label="Permalink"
            text={permalink}
            onUpdate={(t) => setPermalink(t)}
            fullWidth
            placeholder="a-cool-little-text-for-the-post"
            helperText="The text shown in the URL after /blog. Shows post ID if empty."
          />
          <Stack spacing={"8px"}>
            <Typography fontWeight={500} fontSize={14}>
              Tags
            </Typography>
            <Autocomplete
              options={[]}
              disablePortal
              freeSolo
              autoSelect
              multiple
              onChange={(ev, values) => {
                setTags(values);
              }}
              defaultValue={tags}
              value={tags}
              fullWidth
              ListboxProps={{
                sx: { background: "#FFF2", backdropFilter: "blur(1px)" },
              }}
              renderTags={(tags, tProps, state) =>
                tags.map((t: any) => (
                  <Chip
                    deleteIcon={<Close height="16px" width="16px" />}
                    onDelete={(de) => {
                      // Remove from local
                      const _new = tags?.filter?.((s: any) => s !== t);
                      setTags(_new);
                    }}
                    size="small"
                    label={t}
                    variant="filled"
                    color="primary"
                    sx={{
                      color: "#FFF",
                      borderRadius: "24px",
                      mx: "2px",
                    }}
                  />
                ))
              }
              sx={{
                borderRadius: "50px",
              }}
              size="small"
              getOptionLabel={(option: any) => option.label}
              renderInput={(params) => (
                <TextField
                  variant="filled"
                  {...(params as any)}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    hiddenLabel: true,
                    sx: { borderRadius: "8px" },
                  }}
                  hiddenLabel
                  helperText={
                    "Tags are useful when filtering posts of certain types, or in search."
                  }
                  placeholder={"Enter tags for the post. "}
                />
              )}
            />
          </Stack>
          <Stack spacing={"8px"} position={"relative"}>
            <Typography fontWeight={500} fontSize={14}>
              Thumbnail
            </Typography>

            <img
              src={thumbnail || LOGO_Z}
              style={{
                width: "360px",
                height: "300px",
                borderRadius: "8px",
                opacity: 0.5,
                objectFit: "contain",
              }}
            />
            <input
              id="file-input-1"
              style={{
                position: "absolute",
                opacity: 0,
                zIndex: -999999,
              }}
              type="file"
              accept="image/*"
              onChange={(f) => {
                if (f.target.files && f.target.files?.length > 0) {
                  uploadPhoto(f.target.files[0]);
                }
              }}
            />
            <Button
              onClick={() => document.getElementById("file-input-1")?.click()}
              sx={{ alignSelf: "flex-end" }}
              variant="contained"
            >
              Upload a Picture
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
