import React from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import Glow from "../../components/glow";
export default function StaticWhy() {
  const theme = useTheme();
  const desktop = !useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Stack
      sx={{
        mt: desktop ? "60px" : "120px",
        position: "relative",
        overflow: "visible",
      }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Glow
        color={theme.palette.primary.main}
        size="750px"
        sx={{ opacity: 0.2, zIndex: -1 }}
      />
      <Typography
        sx={{
          fontSize: desktop ? "48px" : "28px",
          fontWeight: 700,
          fontFamily: "Sk-Modernist",
        }}
      >
        Why Choose{" "}
        <span style={{ color: theme.palette.secondary.light }}>Us?</span>
      </Typography>
      <Typography
        sx={{
          fontSize: desktop ? "20px" : "14px",
          fontWeight: 400,
          maxWidth: desktop ? "700px" : "100%",
          px: "24px",
          textAlign: "center",
        }}
      >
        We deliver high-quality bot, app, and API development and integration
        services, on time and on budget.
      </Typography>

      {/* The why choose us cards  */}
      <Stack
        direction={"row"}
        sx={{ mt: desktop ? "64px" : "32px" }}
        spacing={desktop ? "0px" : 0}
        rowGap={desktop ? 0 : "14px"}
        columnGap={desktop ? 0 : "14px"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack
          sx={{
            px: "24px",
            borderLeft: "2px solid " + theme.palette.secondary.light,
            minWidth: desktop ? "200px" : "170px",
          }}
        >
          <Typography
            sx={{ fontSize: desktop ? "48px" : "32px", fontWeight: 700 }}
          >
            90<span style={{ color: theme.palette.secondary.light }}>+</span>
          </Typography>
          <Typography
            sx={{ fontSize: desktop ? "14px" : "12px", fontWeight: 600 }}
          >
            Projects completed
          </Typography>
        </Stack>

        <Stack
          sx={{
            px: "24px",
            borderLeft: "2px solid " + theme.palette.secondary.light,
            minWidth: desktop ? "200px" : "170px",
          }}
        >
          <Typography
            sx={{ fontSize: desktop ? "48px" : "32px", fontWeight: 700 }}
          >
            95<span style={{ color: theme.palette.secondary.light }}>%</span>
          </Typography>
          <Typography
            sx={{ fontSize: desktop ? "14px" : "12px", fontWeight: 600 }}
          >
            Client Satisfaction
          </Typography>
        </Stack>

        <Stack
          sx={{
            px: "24px",
            borderLeft: "2px solid " + theme.palette.secondary.light,
            minWidth: desktop ? "200px" : "170px",
          }}
        >
          <Typography
            sx={{ fontSize: desktop ? "48px" : "32px", fontWeight: 700 }}
          >
            20<span style={{ color: theme.palette.secondary.light }}>+</span>
          </Typography>
          <Typography
            sx={{ fontSize: desktop ? "14px" : "12px", fontWeight: 600 }}
          >
            Diverse Integrations
          </Typography>
        </Stack>

        <Stack
          sx={{
            px: "24px",
            borderLeft: "2px solid " + theme.palette.secondary.light,
            minWidth: desktop ? "200px" : "170px",
          }}
        >
          <Typography
            sx={{ fontSize: desktop ? "48px" : "32px", fontWeight: 700 }}
          >
            99<span style={{ color: theme.palette.secondary.light }}>%</span>
          </Typography>
          <Typography
            sx={{ fontSize: desktop ? "14px" : "12px", fontWeight: 600 }}
          >
            On-time delivery
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
