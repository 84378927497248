import React, { useEffect, useState } from "react";
import { Badge, Stack, ToggleButton, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../../core/states";
import SectionSettingsProfile from "./profile";

export default function SectionSettings() {
  const [profile] = useRecoilState(aProfile);
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );
  const [range, setRange] = useState<"profile">("profile");

  useEffect(() => {
    setSection(`Settings`);
    setSectionDescription(`View or update your account settings.`);
  }, []);

  return (
    <Stack sx={{ p: "32px", overflow: "hidden" }} flex={1}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        {range === "profile" && (
          <Stack>
            <Typography fontSize={24} fontWeight={400}>
              Profile
            </Typography>
            <Typography fontSize={14} fontWeight={400} color="#94969C">
              View or update your user profile.
            </Typography>
          </Stack>
        )}
      </Stack>
      <SectionSettingsProfile />
    </Stack>
  );
}
