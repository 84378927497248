import { Chip, Stack } from "@mui/material";
import React, { CSSProperties, ReactElement } from "react";
import Glow from "./glow";

export default function ServiceCard(props: {
  imgSrc: any;
  heading: string;
  children: ReactElement[];
  style?: CSSProperties;
  price?: string;
}) {
  return (
    <Stack
      className="serviceCardStyle"
      sx={{
        width: "280px",
        height: "290px",
        border: "1px solid rgba(255, 255, 255, 0.15)",
        p: "24px",
        ...(props.style || {}),
      }}
    >
      {/* <Chip
        color="info"
        size="small"
        label={"Starting from " + props.price}
        sx={{ position: "absolute", top: "24px", right: "24px" }}
      /> */}
      <img
        src={props.imgSrc}
        alt="Service Icon"
        style={{ height: "32px", width: "32px" }}
      />
      <p
        style={{
          color: "#FFF",
          fontWeight: 700,
          fontStyle: "normal",
          lineHeight: "30px",
          fontSize: "26px",
          fontFamily: "Sk-Modernist",
        }}
      >
        {props.heading}
      </p>

      <div style={{ position: "relative" }}>
        <Glow size="450px" color="#48F" />
        {/* Iterate all features, and show them.  */}
        {props.children.map((c) => (
          <p
            key={c.key}
            style={{
              color: "#FFF",
              fontWeight: 400,
              fontStyle: "normal",
              fontSize: "16px",
              lineHeight: "10px",
            }}
          >
            {c}
          </p>
        ))}
      </div>
    </Stack>
  );
}
