import React from "react";
import { Stack } from "@mui/material";
import StaticHero from "./hero";
import StaticWhy from "./why";
import StaticServices from "./services";
import StaticTestimonials from "./testimonials";
import StaticIntegrations from "./integration";
import StaticTeam from "./team";
import StaticContact from "./contact";
import StaticFooter from "./footer";
import StaticProducts from "./products";
import StaticHeader from "./header";
import GoToTop from "../../components/fap";
import Livechat from "../../components/chat";
import StaticBlog from "./blog";

export default function StaticHome() {
  return (
    <Stack>
      <StaticHeader />
      <StaticHero />
      <StaticWhy />
      <StaticServices />
      <StaticTestimonials />
      <StaticIntegrations />
      <StaticProducts />
      <StaticBlog />
      <StaticTeam />
      <StaticContact />
      <StaticFooter />
      <GoToTop />
      <Livechat />
    </Stack>
  );
}
