import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ThemeManager from "./middlewares/ThemeManager";
import PageSplashScreen from "./components/SplashScreen";
import "./index.css";
import { SnackbarProvider } from "notistack";
import { RecoilRoot } from "recoil";
import ModalProvider from "mui-modal-provider";
import { Analytics } from "@vercel/analytics/react";
import PageLogin from "./pages/login";
import PageDashboard from "./pages/dashboard";
import AuthenticatedRoute from "./middlewares/AuthenticatedRoute";
import StaticHome from "./pages/home";
import StrangersTermsOfService from "./pages/strangers/tos";
import StrangersPolicy from "./pages/strangers/policy";
import Ten24Policy from "./pages/1024/policy";
import PageBlog from "./pages/blog";
import PageSinglePost from "./pages/blog/single";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <RecoilRoot>
    {/* The theme provider is added at the root level  */}
    <ThemeManager>
      <ModalProvider>
        <SnackbarProvider
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <PageSplashScreen>
            <BrowserRouter basename="/">
              <Routes>
                <Route path="/" element={<StaticHome />} />
                <Route path="/blog" element={<PageBlog />} />
                <Route path="/blog/:id" element={<PageSinglePost />} />
                <Route path="/login" element={<PageLogin />} />
                <Route
                  path="/admin/*"
                  element={
                    <AuthenticatedRoute redirect={"/login"}>
                      <PageDashboard />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/user/*"
                  element={
                    <AuthenticatedRoute redirect={"/login"}>
                      <PageDashboard />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <AuthenticatedRoute redirect={"/login"}>
                      <PageDashboard />
                    </AuthenticatedRoute>
                  }
                />
                <Route
                  path="/strangers/tos"
                  element={<StrangersTermsOfService />}
                />
                <Route
                  path="/strangers/privacy"
                  element={<StrangersPolicy />}
                />
                <Route path="/1024/privacy" element={<Ten24Policy />} />
              </Routes>
            </BrowserRouter>

            <Analytics />
          </PageSplashScreen>
        </SnackbarProvider>
      </ModalProvider>
    </ThemeManager>
  </RecoilRoot>
);
