import _clientAvatar1 from "./ClientAvatar1.png";
import _clientAvatar2 from "./ClientAvatar2.png";
import _clientAvatar3 from "./ClientAvatar3.png";
import _clientAvatar4 from "./ClientAvatar4.png";
import _clientAvatar5 from "./ClientAvatar5.png";
import _scribble from "./scribble.svg";

export const ClientAvatar1 = _clientAvatar2;

export const ClientAvatar2 = _clientAvatar2;

export const ClientAvatar3 = _clientAvatar3;

export const ClientAvatar4 = _clientAvatar4;

export const ClientAvatar5 = _clientAvatar5;

export const Scribble = _scribble;
