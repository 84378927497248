import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { CSSProperties } from "react";

export default function SectionDivider(props: {
  title1: string;
  title2: string;
  className?: string;
  style?: CSSProperties;
}) {
  const desktop = useMediaQuery("screen and (min-width: 710px)");
  const theme = useTheme();
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
      style={{
        position: "relative",
      }}
    >
      <svg viewBox="0 0 10 1.4" style={{ position: "absolute" }}>
        <text
          x="5"
          y="1"
          text-anchor="middle"
          font-size="1"
          fill="none"
          stroke-width=".015"
          stroke="#FFFFFF1A"
          font-family={"Sk-Modernist"}
        >
          {props.title1 + props.title2}
        </text>
      </svg>

      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          gap: "0px",
        }}
      >
        <h3
          style={{ fontSize: desktop ? "68px" : "48px", color: "#FFF" }}
          font-family={"Sk-Modernist"}
        >
          {props.title1}
        </h3>
        <h3
          style={{
            fontSize: desktop ? "68px" : "48px",
            color: theme.palette.secondary.light,
          }}
          font-family={"Sk-Modernist"}
        >
          {props.title2}
        </h3>
      </div>
    </Stack>
  );
}
