import React from "react";
import { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { enqueueSnackbar } from "notistack";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../../core/states";

export default function SectionAdminDashboard() {
  const [profile] = useRecoilState(aProfile);
  const [section, setSection] = useRecoilState(aCurrentSection);
  const [sectionDescription, setSectionDescription] = useRecoilState(
    aCurrentSectionDescription
  );

  const [busy, setBusy] = useState(false);

  async function load() {
    try {
      setBusy(true);
    } catch (err: any) {
      enqueueSnackbar("Error loading data. Please try again. ", {
        variant: "error",
      });
    }
    setBusy(false);
  }

  useEffect(() => {
    setSection(`Dashboard`);
    setSectionDescription(`Welcome back, ${profile?.name}!`);
  }, []);

  return (
    <Stack sx={{ p: "32px" }}>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ width: "100%" }}
      >
        <Stack>
          <Typography fontSize={24} fontWeight={400}>
            Insights
          </Typography>
          <Typography fontSize={14} fontWeight={400} color="#94969C">
            Current balance, sales, and new customers.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
