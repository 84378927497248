import { Typography } from "@mui/material";
import React, { CSSProperties, ReactElement } from "react";

export default function IntegrationCard(props: {
  imgSrc: any;
  heading: string;
  children: ReactElement[];
  style?: CSSProperties;
}) {
  return (
    <div
      className="serviceCardStyle"
      style={{
        // display: "flex",
        width: "280px",
        height: "290px",
        background: "rgba(255, 255, 255, 0.05)",
        border: "1px solid rgba(255, 255, 255, 0.11)",
        backdropFilter: "blur(2.700000047683716px)",
        color: "white",
        ...(props.style || {}),
      }}
    >
      <div
        style={{
          margin: "24px 24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          src={props.imgSrc}
          alt="Service Icon"
          style={{ height: "52px", width: "52px" }}
        />
        <p
          style={{
            color: "#E8E8E8",
            fontWeight: 700,
            fontStyle: "normal",
            lineHeight: "30px",
            fontSize: "26px",
            fontFamily: "Sk-Modernist",
          }}
        >
          {props.heading}
        </p>
        <div>
          {/* Iterate all features, and show them.  */}
          {props.children.map((c) => (
            <p
              key={c.key}
              style={{
                color: "#BCBCBC",
                fontWeight: 400,
                fontStyle: "normal",
                fontSize: "16px",
                lineHeight: "10px",
              }}
            >
              {c}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}
