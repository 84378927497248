import { ensureAuthentication, getRtmClient } from ".";
import { PaginatedResponse } from "../types/IPaginatedResponse";
import { User } from "../types/User";

/**
 * Lists all the users on the platform, sorted by registeration time in descending. Requires administrator role.
 * @param pageNumber
 * @returns
 */
export async function rtmListUsers(props: {
  page: number;
  sort?: {
    type:
      | "lastSigninTimestamp"
      | "registeredTimestamp"
      | "name"
      | "status"
      | "role";
    value: "desc" | "asc";
  };
  search?: string;
}) {
  await ensureAuthentication();
  const users = await getRtmClient().CallWait<PaginatedResponse<User>>(
    "rtmListUsers",
    {
      page: props.page,
      sort: props.sort,
      search: props.search,
    }
  );
  return users;
}

/**
 * Creates a new user with specified details.
 * @returns
 */
export async function rtmAddUser(user: Partial<User>) {
  await ensureAuthentication();
  const newuser = await getRtmClient().CallWait<{
    user: User;
    password: string;
  }>("rtmAddUser", user);
  return newuser;
}

export async function rtmDisableUser(uid: string) {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<boolean>("rtmDisableUser", uid);
  return _sub;
}

export async function rtmEnableUser(uid: string) {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<boolean>("rtmEnableUser", uid);
  return _sub;
}

export async function rtmSuspendUser(uid: string) {
  await ensureAuthentication();
  const _sub = await getRtmClient().CallWait<boolean>("rtmSuspendUser", uid);
  return _sub;
}
