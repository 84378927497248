import React from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import SectionDivider from "../../components/section_divider";
import TestimonialCard from "../../components/testimonial_card";

export default function StaticTestimonials() {
  const theme = useTheme();
  const desktop = !useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <div id="testimonials">
      <Stack sx={{ mt: desktop ? "250px" : "120px" }}>
        <SectionDivider title1="Testim" title2="onials" />
        <Stack
          sx={{ mt: desktop ? "120px" : "64px" }}
          justifyContent={"center"}
          direction={"row"}
          padding={"35px"}
          rowGap={"24px"}
          columnGap={"24px"}
          flexWrap={"wrap"}
          className="testimonialsContainer"
        >
          <TestimonialCard
            text="I am extremely satisfied with the services provided by Zexware. They developed a remarkable Discord bot integrated with Stripe payments, which was truly impressive. Moreover, the support they offered was incredibly helpful and made a significant difference for me. The team at Zexware went above and beyond to ensure that my experience was smooth and successful. I highly recommend their services to anyone in need of expert bot development and exceptional customer support. Thank you, Zexware, for delivering such an outstanding service!"
            authorName="jonnychuck625"
            authorCountry="Italy"
          />
          <TestimonialCard
            text="Wanted a Telegram bot with Stripe, OpenAI and Elevenlabs integration. Zexware did a phenomenal job with the project and finished before schedule. No issues at all and really went above and beyond. I will be using him again in the future for other projects. Highly recommend!"
            authorName="jakobsenshop"
            authorCountry="Norway"
          />
          <TestimonialCard
            text="Great experience working with AB and Zexware. He's a talented developer, easy to communicate with, and clearly knowledgable in both Node.js and React.js. He works hard, and finished well before his self-imposed deadline. Will come to him first next time we need software development work. Highly recommended."
            authorName="a_n_io"
            authorCountry="United States"
          />
        </Stack>
        {/*  */}
      </Stack>
    </div>
  );
}
