import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Link,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import GoToTop from "../../components/fap";
import Livechat from "../../components/chat";
import StaticHeader from "../home/header";
import StaticFooter from "../home/footer";
import SectionDivider from "../../components/section_divider";
import PFMInput from "../../components/PFMInput";
import { ArrowBackIos, ArrowLeft, Search } from "@mui/icons-material";
import { BlogPost } from "../../types/Post";
import axios from "axios";
import { AppConfig } from "../../config";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useParams } from "react-router";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

export default function PageSinglePost() {
  const theme = useTheme();
  const desktop = !useMediaQuery(theme.breakpoints.down("lg"));

  const params = useParams();

  const postId = params.id;

  const [busy, setBusy] = useState(true);

  const [post, setPost] = useState<
    BlogPost & { authorName: string; authorImage: string }
  >();

  async function load() {
    try {
      //Load data
      setBusy(true);
      const _post = await axios.get(AppConfig.server.rest + "/blog/" + postId);
      if (_post.data) {
        setPost(_post.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  useEffect(() => {
    load();
  }, []);

  return (
    <Stack sx={{ minHeight: "100vh" }}>
      <StaticHeader />
      {post && (
        <Stack
          flex={1}
          sx={{
            maxWidth: "1280px",
            width: "100%",
            alignSelf: "center",
            mt: "120px",
            py: "12px",
          }}
          spacing={"12px"}
        >
          <Link
            href="/blog"
            sx={{
              color: "white",
              textDecoration: "none",
              alignItems: "center",
              display: "flex",
            }}
          >
            <SvgIcon>
              <ArrowBackIos />
            </SvgIcon>
            Back to Posts
          </Link>
          <Stack
            sx={{ pt: "40px" }}
            direction={"row"}
            alignItems={"center"}
            spacing={"8px"}
          >
            <Avatar src={post.authorImage} alt={post.authorName} />
            <Stack spacing={"4px"}>
              <Typography fontSize={14}>{post.authorName}</Typography>
              <Typography fontSize={12} sx={{ opacity: 0.7 }}>
                {moment.unix(post.created).format("DD/MM/YYYY hh:mm A (Z)")}
              </Typography>
            </Stack>
          </Stack>
          <Typography fontWeight={600} fontSize={48}>
            {post.title}
          </Typography>
          {post.thumbnail && (
            <img
              src={post.thumbnail}
              style={{
                width: "100%",
                objectFit: "contain",
                maxHeight: "600px",
                minHeight: "300px",
                borderRadius: "18px",
              }}
            />
          )}
          {/* Post Contetn */}
          <MDEditor.Markdown
            source={post.content}
            rehypePlugins={[rehypeSanitize]}
            style={{
              background: "transparent",
            }}
          />

          {/* Tags  */}
          <Divider />
          <Typography>Tags</Typography>
          <Stack direction={"row"} spacing={"8px"} flexWrap={"wrap"}>
            {post.tags?.map((t) => (
              <Chip label={t} sx={{ textTransform: "capitalize" }} />
            ))}
          </Stack>
        </Stack>
      )}

      <StaticFooter />
      <GoToTop />
      <Livechat />
    </Stack>
  );
}
