import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  IconButton,
  Popover,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../../core/states";
import { User } from "../../../types/User";
import { rtmListUsers } from "../../../rtm/admin";
import ZTable from "../../../components/ZTable";
import PFMInput from "../../../components/PFMInput";
import {
  PersonAdd,
  Refresh,
  Search,
  Sort,
  SortByAlpha,
} from "@mui/icons-material";
import AddUserDialog from "../../../dialogs/AddUser";
import SuspendedUserDialog from "../../../dialogs/SuspendUser";
import EditUserDialog from "../../../dialogs/EditUser";

export default function SectionAdminUsers() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [busy, setBusy] = useState(true);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [data, setData] = useState<User[]>([]);
  const { showModal } = useModal();

  const [sort, setSort] = useState<{
    type: "lastSigninTimestamp" | "registeredTimestamp" | "name" | "role";
    value: "asc" | "desc";
  }>();

  const [search, setSearch] = useState("");
  const [searchRaw, setSearchRaw] = useState("");

  async function loadUsers(page: number) {
    try {
      //Load data
      setBusy(true);
      const users = await rtmListUsers({
        page: page,
        sort: sort,
        search: search,
      });
      if (users) {
        setTotalPages(users.pageCount);
        setPageNumber(users.currentPage);
        setTotalUsers(users.total);
        setData(users.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  function showAddUserDialog() {
    const modal = showModal(AddUserDialog, {
      closeHandler(result) {
        modal.destroy();
        loadUsers(pageNumber);
      },
    });
  }

  function showEditDialog(usr: User) {
    if (usr.suspended) {
      const modal = showModal(SuspendedUserDialog, {
        closeHandler(result) {
          modal.destroy();
          loadUsers(pageNumber);
        },
        data: usr,
      });
    } else {
      const modal = showModal(EditUserDialog, {
        closeHandler(result) {
          modal.destroy();
          loadUsers(pageNumber);
        },
        data: usr,
      });
    }
  }

  useEffect(() => {
    setSection(`Identity and Access Management`);
    setSectionDescription(
      `Manage users who has access to the platform with roles.`
    );

    // Load page 0
    loadUsers(0);
  }, []);

  useEffect(() => {
    loadUsers(pageNumber);
  }, [sort, search]);

  function RenderUser(user: User) {
    return (
      <TableRow
        onClick={() => showEditDialog(user)}
        sx={{
          transition: "all .2s",
          ":hover": {
            background: "#FFF2",
            cursor: "pointer",
          },
          ":active": {
            background: "#FFF1",
          },
        }}
      >
        <TableCell>
          <Typography
            color="#94969C"
            title="Click to copy!"
            onClick={() => navigator.clipboard.writeText(user.uid)}
          >
            {user.uid.substring(0, 4)}...
            {user.uid.substring(user.uid.length - 4)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={user.image || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {user.name}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {user.email}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            <Chip
              label={
                user.suspended
                  ? "Suspended"
                  : user.disabled
                  ? "Disabled"
                  : user.emailVerified
                  ? "Active"
                  : "Unverified"
              }
              variant="outlined"
              color={
                user.suspended
                  ? "error"
                  : user.disabled
                  ? "warning"
                  : user.emailVerified
                  ? "success"
                  : "info"
              }
              size="small"
            />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {moment
              .unix(user.registeredTimestamp)
              .format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="#94969C">
            {moment
              .unix(user.lastSigninTimestamp)
              .format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          <Chip label={user.role} variant="identifier" size="small" />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "12px" }} flex={1}>
      <ZTable
        sx={{ height: "100%" }}
        busy={busy}
        pageNumber={pageNumber}
        paginated
        totalPages={totalPages}
        nextPage={(pn) => loadUsers(pn)}
        prevPage={(pn) => loadUsers(pn)}
        titleBadge={totalUsers + " Users"}
        title="Users"
        description="List of all the users on the platform."
        actionButtons={
          <Stack direction={"row"} spacing={"12px"} alignItems={"center"}>
            <PFMInput
              text={searchRaw}
              onUpdate={(r) => setSearchRaw(r)}
              onReturn={() => setSearch(searchRaw)}
              startIcon={Search}
              placeholder="Search user..."
            />
            <Button
              disabled={busy}
              sx={{ width: "200px", height: "48px" }}
              onClick={() => loadUsers(pageNumber)}
              variant="contained"
              color="secondary"
              startIcon={
                <SvgIcon>
                  <Refresh />
                </SvgIcon>
              }
            >
              Refresh
            </Button>
            <Button
              sx={{ width: "200px", height: "48px" }}
              onClick={showAddUserDialog}
              disabled={busy}
              variant="contained"
              color="primary"
              startIcon={
                <SvgIcon>
                  <PersonAdd />
                </SvgIcon>
              }
            >
              Add User
            </Button>
          </Stack>
        }
        tableHead={
          <TableRow>
            {/* ID */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>ID</Typography>
              </Stack>
            </TableCell>
            {/* User  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "name",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>User</Typography>
                {sort?.type === "name" && (
                  <SvgIcon>
                    {sort.value === "asc" && <SortByAlpha />}
                    {sort.value === "desc" && <Sort />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
            {/* Status  */}
            <TableCell sx={{ cursor: "pointer" }}>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Status</Typography>
              </Stack>
            </TableCell>
            {/* Registered  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "registeredTimestamp",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Registered</Typography>
                {sort?.type === "registeredTimestamp" && (
                  <SvgIcon>
                    {sort.value === "asc" && <SortByAlpha />}
                    {sort.value === "desc" && <Sort />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
            {/* Last Activity  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "lastSigninTimestamp",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Last Activity</Typography>
                {sort?.type === "lastSigninTimestamp" && (
                  <SvgIcon>
                    {sort.value === "asc" && <SortByAlpha />}
                    {sort.value === "desc" && <Sort />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
            {/* Role  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "role",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Role</Typography>
                {sort?.type === "role" && (
                  <SvgIcon>
                    {sort.value === "asc" && <SortByAlpha />}
                    {sort.value === "desc" && <Sort />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderUser(d))}</>}
      />
    </Stack>
  );
}
