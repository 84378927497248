import React from "react";
import { useState, useEffect } from "react";
import { Stack, Typography } from "@mui/material";

import { Player } from "@lottiefiles/react-lottie-player";
import { aProfile } from "../core/states";
import { useRecoilState } from "recoil";
import { ensureAuthentication, rtmGetProfile } from "../rtm";
import { FbAuth } from "../core/firebase";

export default function PageSplashScreen({ children }: any) {
  const [initialized, setInitialized] = useState(false);
  const [profile, setProfile] = useRecoilState(aProfile);

  async function loadProfile() {
    try {
      const prof = await rtmGetProfile();
      setProfile(prof);
      console.log(prof);
    } catch (err: any) {
      console.log(err);
    }
  }

  useEffect(() => {
    FbAuth.authStateReady().then(async () => {
      if (FbAuth.currentUser) {
        await ensureAuthentication();
        await loadProfile();
      }
      setInitialized(true);
    });
  }, []);

  if (initialized) {
    return <>{children}</>;
  }

  return (
    <Stack sx={{ height: "100vh" }} className="scaling-element">
      <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
        <Player
          autoplay
          loop
          src="https://lottie.host/34249faf-c0ab-43d9-a72c-abf0272a7643/HE11ilgVOu.json"
          style={{ height: "380px" }}
        />
      </Stack>
    </Stack>
  );
}
