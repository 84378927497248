import React, { useEffect } from "react";
import { useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { signOut } from "firebase/auth";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router";
import { AppConfig } from "../config";
import { aProfile } from "../core/states";
import { Role } from "../types/Role";
import { LOGO_HEADER } from "../assets";
import {
  Dashboard,
  Logout,
  MessageOutlined,
  People,
  Settings,
} from "@mui/icons-material";
import { FbAuth } from "../core/firebase";

export default function Sidebar() {
  const navigate = useNavigate();
  const [profile] = useRecoilState(aProfile);
  const loc = useLocation();
  const segs = loc.pathname.split("/");

  const [selectedItem, setSelectedItem] = useState<
    "dashboard" | "users" | "livechat" | "settings" | "blog"
  >(segs && segs.length > 0 ? (segs[segs.length - 1] as any) : "dashboard");

  const theme = useTheme();

  useEffect(() => {
    if (!selectedItem) return;
    // if the user is admin
    if (profile?.role === Role.Admin) {
      // use /admin/ sub-route
      navigate("/admin/" + selectedItem);
    } else {
      navigate("/user/" + selectedItem);
    }
  }, [selectedItem]);

  return (
    <Stack
      sx={{
        width: "280px",
        px: "20px",
        py: "32px",
        m: "12px",
        background: "#FFFFFF13",
        "& .MuiButton-containedPrimary": {
          borderRadius: "50px",
        },
        "& .MuiButton-containedPrimary:hover": {
          background: "rgba(124,172,248,0.08)",
        },
        borderRadius: "12px",
      }}
      spacing="32px"
    >
      <Stack direction={"row"}>
        <img src={LOGO_HEADER} alt="Logo" style={{ height: "60px" }} />
      </Stack>

      {/* Items  */}
      <Stack
        sx={{ py: "16px", overflow: "auto", overflowX: "hidden" }}
        spacing="8px"
      >
        {/* Admin Buttons  */}
        {profile?.role === Role.Admin && (
          <>
            {/* Dashboard */}
            <Button
              onClick={() => setSelectedItem("dashboard")}
              disableElevation
              sx={{
                borderRadius: "8px",
                justifyContent: "start",
                alignItems: "center",
                pointerEvents:
                  selectedItem === "dashboard" ? "none" : undefined,
                background:
                  selectedItem === "dashboard" ? undefined : "transparent",
                color: selectedItem === "dashboard" ? "#FCFCFD" : "#94969C",
              }}
              color="primary"
              variant="contained"
              startIcon={
                <SvgIcon
                  htmlColor={selectedItem === "dashboard" ? "#FCFCFD" : ""}
                >
                  <Dashboard />
                </SvgIcon>
              }
            >
              Dashboard
            </Button>

            <Button
              onClick={() => setSelectedItem("blog")}
              disableElevation
              sx={{
                borderRadius: "8px",
                justifyContent: "start",
                alignItems: "center",
                pointerEvents: selectedItem === "blog" ? "none" : undefined,
                background: selectedItem === "blog" ? undefined : "transparent",
                color: selectedItem === "blog" ? "#FCFCFD" : "#94969C",
              }}
              variant="contained"
              startIcon={
                <SvgIcon htmlColor={selectedItem === "blog" ? "#FCFCFD" : ""}>
                  <MessageOutlined />
                </SvgIcon>
              }
            >
              Blog
            </Button>

            {/* I AM  */}
            <Button
              onClick={() => setSelectedItem("users")}
              disableElevation
              sx={{
                borderRadius: "8px",
                justifyContent: "start",
                alignItems: "center",
                pointerEvents: selectedItem === "users" ? "none" : undefined,
                background:
                  selectedItem === "users" ? undefined : "transparent",
                color: selectedItem === "users" ? "#FCFCFD" : "#94969C",
              }}
              variant="contained"
              startIcon={
                <SvgIcon htmlColor={selectedItem === "users" ? "#FCFCFD" : ""}>
                  <People />
                </SvgIcon>
              }
            >
              IAM Center
            </Button>
          </>
        )}

        {/* User Buttons  */}
        {profile?.role === Role.Editor && (
          <>
            <Button
              onClick={() => setSelectedItem("blog")}
              disableElevation
              sx={{
                borderRadius: "8px",
                justifyContent: "start",
                alignItems: "center",
                pointerEvents: selectedItem === "blog" ? "none" : undefined,
                background: selectedItem === "blog" ? undefined : "transparent",
                color: selectedItem === "blog" ? "#FCFCFD" : "#94969C",
              }}
              variant="contained"
              startIcon={
                <SvgIcon htmlColor={selectedItem === "blog" ? "#FCFCFD" : ""}>
                  <MessageOutlined />
                </SvgIcon>
              }
            >
              Blog
            </Button>
          </>
        )}
      </Stack>

      <Stack flex={1} />

      <Stack spacing={"8px"}>
        <Button
          onClick={() => setSelectedItem("settings")}
          disableElevation
          sx={{
            borderRadius: "8px",
            justifyContent: "start",
            alignItems: "center",
            pointerEvents: selectedItem === "settings" ? "none" : undefined,
            background: selectedItem === "settings" ? undefined : "transparent",
            color: selectedItem === "settings" ? "#FCFCFD" : "#94969C",
          }}
          variant="contained"
          startIcon={
            <SvgIcon htmlColor={selectedItem === "settings" ? "#FCFCFD" : ""}>
              <Settings />
            </SvgIcon>
          }
        >
          Settings
        </Button>
      </Stack>
      <Stack direction={"row"} alignItems={"center"} spacing="12px">
        <Avatar
          sx={{ width: "40px", height: "40px", color: "white" }}
          src={FbAuth.currentUser?.photoURL || ""}
        />
        <Stack flex={1}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#CECFD2",
              maxWidth: "160px",
              wordWrap: "unset",
              overflowWrap: "anywhere",
            }}
          >
            {profile?.name}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#94969C",
              wordBreak: "break-word",
            }}
          >
            {profile?.email}
          </Typography>
        </Stack>
        <IconButton
          onClick={() => {
            signOut(FbAuth);
            window.location.reload();
          }}
          title="Sign out"
        >
          <SvgIcon>
            <Logout />
          </SvgIcon>
        </IconButton>
      </Stack>

      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography fontSize={10}>{AppConfig.build}</Typography>
      </Stack>
    </Stack>
  );
}
