import React from "react";
import { Stack, Typography } from "@mui/material";
import StaticHeader from "../home/header";
import StaticFooter from "../home/footer";
import DualHeading from "../../components/dual_heading";
import GoToTop from "../../components/fap";
import Livechat from "../../components/chat";

const TERMS = [
  {
    title: "Modification of Terms",
    text: "We reserve the right to update or modify the terms and conditions at any time. Users will be notified of changes, and continued use of the application implies acceptance of the revised terms.",
  },
  {
    title: "Content Guidelines",
    text: "Users are strictly prohibited from selling or promoting content that violates any laws, including but not limited to any illegal material.",
  },
  {
    title: "Liability",
    text: "We are not liable for the content shared by the users. Users are solely responsible for the legality and appropriateness of their actions on the platform and application.",
  },
  {
    title: "Service Interruptions",
    text: "We reserve the right to interrupt, suspend, or terminate our services at any time for maintenance, upgrades, or any other reasons. Users will be notified whenever possible.",
  },
  {
    title: "Indemnification",
    text: "Users agree to indemnify and hold us harmless from any claims, losses, or damages arising from their use of the application.",
  },
  {
    title: "Warranty Disclaimer",
    text: "Your use of the Service is at your sole risk. The Service is provided on an 'AS IS' and 'AS AVAILABLE' basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.",
  },
  {
    title: "External Links",
    text: "Our Service may contain links to third-party web sites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services. We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.",
  },
];
export default function StrangersTermsOfService() {
  return (
    <Stack
      flex={1}
      sx={{
        bgcolor: "#030712",
        scrollBehavior: "smooth",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <StaticHeader />

      <Stack flex={1} sx={{ maxWidth: "1280px", my: "120px" }}>
        <DualHeading
          h1="Strangers: "
          h2="Chat and Talk to Strangers"
          sx={{ fontSize: "28px" }}
          h2sx={{ color: "#48F" }}
        />
        <DualHeading
          h1="Terms of "
          h2="Service"
          sx={{ fontSize: "26px" }}
          h2sx={{ color: "#48F" }}
        />
        <DualHeading
          h1="Last updated: "
          h2="March 24th, 2024"
          sx={{ fontSize: "16px" }}
          h2sx={{ color: "#48F" }}
        />
        <DualHeading
          h1="For further information or inquiries, please contact us at "
          h2="ab@zexware.com"
          sx={{ fontSize: "12px" }}
          h2sx={{ color: "#48F" }}
        />
        <br />
        <Typography>
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service.
          <br />
          <br />
          Please read these Terms and Conditions ("Terms", "Terms and
          Conditions") carefully before using the "Strangers: Chat and Talk to
          Strangers" app, herein referred as the "Service" operated by Zexware
          (Private) Limited ("us", "we", or "our").
          <br />
          <br />
          Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who access or use the Service.
        </Typography>
        <br />
        <br />
        <br />
        <Stack spacing={"24px"}>
          {TERMS.map((t) => (
            <>
              <Typography
                sx={{ px: t.text ? "14px" : undefined }}
                color={"primary"}
                fontSize={16}
                fontWeight={500}
              >
                {t.title}
              </Typography>
              {t.text && (
                <Typography sx={{ pl: "28px" }} fontSize={16} fontWeight={400}>
                  {t.text}
                </Typography>
              )}
            </>
          ))}
        </Stack>
      </Stack>

      {/* Footer  */}
      <StaticFooter />
      <GoToTop />
      <Livechat />
    </Stack>
  );
}
