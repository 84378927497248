import React, { useState } from "react";
import {
  Autocomplete,
  Avatar,
  Chip,
  FormHelperText,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";

export interface AutocompleteOption {
  label: string;
  icon?: string;
  [key: string]: any;
}

export default function ZAutoComplete(props: {
  label?: string;
  important?: boolean;
  onUpdate?: (value: AutocompleteOption | AutocompleteOption[]) => void;
  placeholder?: string;
  errorText?: string;
  helperText?: string;
  fullWidth?: boolean;
  multiline?: boolean;
  multiple?: boolean;
  rows?: number;
  options?: AutocompleteOption[];
  value?: AutocompleteOption | AutocompleteOption[];
  onDelete?: (value: AutocompleteOption) => void;
  disabled?: boolean;
  freeform?: boolean;
  onOpen?: () => void;
}) {
  const [_options, _setOptions] = useState<AutocompleteOption[]>(
    props.options || []
  );
  const [_selectedOptions, _setSelectedOptions] = useState<
    AutocompleteOption | AutocompleteOption[]
  >(props.value as any);

  return (
    <Stack sx={{ width: "100%", height: "100%" }} spacing={"6px"}>
      {props.label && (
        <Typography
          fontFamily={"Inter"}
          fontWeight={"medium"}
          fontSize={"14px"}
          sx={{ display: "flex" }}
        >
          {props.label}
          {props.important && <Typography color="error">*</Typography>}
        </Typography>
      )}
      <Autocomplete
        onOpen={props.onOpen}
        disabled={props.disabled}
        disablePortal
        freeSolo={props.freeform}
        filterSelectedOptions
        disableClearable={props.important}
        options={_options}
        isOptionEqualToValue={(ao: any, aov: any) => {
          if (ao.id && aov.id) return ao.id === aov.id;
          else return ao?.label?.toLowerCase() === aov?.label?.toLowerCase();
        }}
        autoSelect
        multiple={props.multiple}
        onChange={(ev, values) => {
          props.onUpdate?.(values as any);
          _setSelectedOptions(values as any);
        }}
        defaultValue={props.value}
        value={_selectedOptions}
        fullWidth={props.fullWidth}
        ListboxProps={{
          sx: { background: "#FFF2", backdropFilter: "blur(1px)" },
        }}
        renderOption={(props, option: any, state, ownerState) => {
          return (
            <MenuItem {...props} sx={{ gap: "8px" }}>
              {option.icon && <Avatar src={option.icon} />}
              <Typography> {option.label}</Typography>
              {option.id && (
                <Chip size="small" variant="identifier" label={option.id} />
              )}
            </MenuItem>
          );
        }}
        renderTags={(tags, tProps, state) =>
          tags.map((t: any) => (
            <Chip
              deleteIcon={<Close height="16px" width="16px" />}
              onDelete={(de) => {
                // Remove from local
                const _new = _selectedOptions?.filter?.(
                  (s: any) => s.label !== t.label
                );
                _setSelectedOptions(_new);
                props.onUpdate?.(_new);
              }}
              size="small"
              label={t.label}
              avatar={t.icon ? <Avatar src={t.icon} /> : undefined}
              variant="filled"
              color="primary"
              sx={{
                color: "#FFF",
                borderRadius: "24px",
              }}
            />
          ))
        }
        sx={{
          borderRadius: "50px",
        }}
        size="small"
        getOptionLabel={(option: any) => option.label}
        renderInput={(params) => (
          <TextField
            variant="filled"
            {...(params as any)}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              hiddenLabel: true,
              sx: { borderRadius: "50px" },
            }}
            multiline={props.multiline}
            rows={props.rows}
            hiddenLabel
            error={Boolean(props.errorText)}
            helperText={props.helperText}
            placeholder={props.placeholder}
          />
        )}
      />
    </Stack>
  );
}
