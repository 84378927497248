import { Button, Stack, Typography } from "@mui/material";
import React from "react";

export default function Product(props: {
  logo: any;
  name: string;
  description: string;
  primaryButtonUrl?: string;
  primaryButtonText?: string;
  learnMoreUrl?: string;
  color: string;
  onClick?: () => void;
}) {
  return (
    <Stack
      flex={1}
      spacing={"16px"}
      sx={{
        minWidth: "350px",
        width: "350px",
        backgroundColor: "#1F293733",
        borderRadius: "32px",
        padding: "  30px",
        color: "#EDEDED",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "24px",
        border: `1px solid #FFF2`,
        cursor: "pointer",
        transition: "all .2s",
        ":hover": {
          border: "1px solid " + props.color + "A",
        },
      }}
    >
      <img
        src={props.logo}
        alt=""
        width={"96px"}
        height={"96px"}
        style={{
          borderRadius: "50%",
          background: "white",
          objectFit: "contain",
        }}
      ></img>

      <Typography
        sx={{
          color: "#FFF",
          fontFamily: "Sk-Modernist",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "700",
        }}
      >
        {props.name}
      </Typography>
      <Typography>{props.description}</Typography>
      <Stack
        flex={1}
        direction={"row"}
        spacing={"12px"}
        alignItems={"flex-end"}
      >
        {props.primaryButtonUrl && (
          <Button variant="contained" href={props.primaryButtonUrl}>
            {props.primaryButtonText}
          </Button>
        )}
        {props.learnMoreUrl && (
          <Button href={props.learnMoreUrl}>Learn more</Button>
        )}
      </Stack>
    </Stack>
  );
}
