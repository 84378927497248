import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  Stack,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useRecoilState } from "recoil";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { useModal } from "mui-modal-provider";
import { Add, Refresh, Search, Sort, SortByAlpha } from "@mui/icons-material";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import { BlogPost } from "../../types/Post";
import { rtmListPosts } from "../../rtm";
import ZTable from "../../components/ZTable";
import PFMInput from "../../components/PFMInput";
import EditPostDialog from "../../dialogs/AddPost";

export default function SectionPosts() {
  const [profile] = useRecoilState(aProfile);
  const [, setSection] = useRecoilState(aCurrentSection);
  const [, setSectionDescription] = useRecoilState(aCurrentSectionDescription);
  const [busy, setBusy] = useState(true);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [data, setData] = useState<
    (BlogPost & {
      authorName: string;
      authorImage: string;
      authorEmail: string;
    })[]
  >([]);
  const { showModal } = useModal();

  const [sort, setSort] = useState<{
    type: "title" | "id" | "created" | "updated" | "status";
    value: "asc" | "desc";
  }>();

  const [search, setSearch] = useState("");
  const [searchRaw, setSearchRaw] = useState("");

  async function load(page: number) {
    try {
      //Load data
      setBusy(true);
      const posts = await rtmListPosts({
        page: page,
        sort: sort,
        search: search,
      });
      if (posts) {
        setTotalPages(posts.pageCount);
        setPageNumber(posts.currentPage);
        setTotalUsers(posts.total);
        setData(posts.data);
      }
    } catch (err: any) {
      enqueueSnackbar("Error loading data. ", { variant: "error" });
      console.log(err);
    }
    setBusy(false);
  }

  function showCreatePostDialog() {
    const modal = showModal(EditPostDialog, {
      closeHandler(result) {
        modal.destroy();
        load(pageNumber);
      },
    });
  }

  function showEditDialog(post: BlogPost) {
    const modal = showModal(EditPostDialog, {
      closeHandler(result) {
        modal.destroy();
        load(pageNumber);
      },
      data: post,
    });
  }

  useEffect(() => {
    setSection(`Blog Posts`);
    setSectionDescription(
      `Create, edit, and manage blog posts on the website.`
    );

    // Load page 0
    load(0);
  }, []);

  useEffect(() => {
    load(pageNumber);
  }, [sort, search]);

  function RenderPostItem(
    post: BlogPost & {
      authorName: string;
      authorImage: string;
      authorEmail: string;
    }
  ) {
    return (
      <TableRow
        onClick={() => showEditDialog(post)}
        sx={{
          transition: "all .2s",
          ":hover": {
            background: "#FFF2",
            cursor: "pointer",
          },
          ":active": {
            background: "#FFF1",
          },
        }}
      >
        <TableCell>
          <Typography
            color="#94969C"
            title="Click to copy!"
            onClick={() => navigator.clipboard.writeText(post.id)}
          >
            {post.id}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="#94969C">{post.title}</Typography>
        </TableCell>
        <TableCell align="left">
          <Stack direction={"row"} spacing="12px" alignItems={"center"}>
            <Avatar src={post.authorImage || ""} sx={{ color: "white" }} />
            <Stack>
              <Typography fontSize={14} fontWeight={500}>
                {post.authorName}
              </Typography>
              <Typography fontSize={14} fontWeight={400} color="#94969C">
                {post.authorEmail}
              </Typography>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            <Chip
              label={post.status}
              variant="outlined"
              color={post.status === "draft" ? "default" : "primary"}
              size="small"
            />
          </Typography>
        </TableCell>
        <TableCell>
          <Typography color="#94969C">
            {moment.unix(post.created).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography color="#94969C">
            {moment.unix(post.updated).format("MMM DD, YYYY HH:mm:ss")}
          </Typography>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <Stack sx={{ p: "12px" }} flex={1}>
      <ZTable
        sx={{ height: "100%" }}
        busy={busy}
        pageNumber={pageNumber}
        paginated
        totalPages={totalPages}
        nextPage={(pn) => load(pn)}
        prevPage={(pn) => load(pn)}
        titleBadge={totalUsers + " Users"}
        title="Posts"
        description="List of all the posts. "
        actionButtons={
          <Stack direction={"row"} spacing={"12px"} alignItems={"center"}>
            <PFMInput
              text={searchRaw}
              onUpdate={(r) => setSearchRaw(r)}
              onReturn={() => setSearch(searchRaw)}
              startIcon={Search}
              placeholder="Search..."
            />
            <Button
              disabled={busy}
              sx={{ width: "200px", height: "48px" }}
              onClick={() => load(pageNumber)}
              variant="contained"
              color="secondary"
              startIcon={
                <SvgIcon>
                  <Refresh />
                </SvgIcon>
              }
            >
              Refresh
            </Button>
            <Button
              sx={{ width: "200px", height: "48px" }}
              onClick={showCreatePostDialog}
              disabled={busy}
              variant="contained"
              color="primary"
              startIcon={
                <SvgIcon>
                  <Add />
                </SvgIcon>
              }
            >
              New
            </Button>
          </Stack>
        }
        tableHead={
          <TableRow>
            {/* ID */}
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>ID</Typography>
              </Stack>
            </TableCell>
            <TableCell>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Title</Typography>
              </Stack>
            </TableCell>
            {/* User  */}
            <TableCell sx={{ cursor: "pointer" }}>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Author</Typography>
              </Stack>
            </TableCell>
            {/* Status  */}
            <TableCell sx={{ cursor: "pointer" }}>
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Status</Typography>
              </Stack>
            </TableCell>
            {/* Registered  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "created",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Created</Typography>
                {sort?.type === "created" && (
                  <SvgIcon>
                    {sort.value === "asc" && <SortByAlpha />}
                    {sort.value === "desc" && <Sort />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
            {/* Last Activity  */}
            <TableCell
              sx={{ cursor: "pointer" }}
              onClick={() =>
                setSort({
                  type: "updated",
                  value: sort?.value === "asc" ? "desc" : "asc",
                })
              }
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography>Updated</Typography>
                {sort?.type === "updated" && (
                  <SvgIcon>
                    {sort.value === "asc" && <SortByAlpha />}
                    {sort.value === "desc" && <Sort />}
                  </SvgIcon>
                )}
              </Stack>
            </TableCell>
          </TableRow>
        }
        tableBody={<>{data.map((d) => RenderPostItem(d))}</>}
      />
    </Stack>
  );
}
