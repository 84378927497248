import React from "react";
import { Stack, Typography } from "@mui/material";
import { IC_CHAT } from "../assets/icon";

export default function ContactCard(props: {
  title?: string;
  description?: string;
  value?: any;
}) {
  return (
    <Stack
      direction={"row"}
      spacing="16px"
      sx={{ alignItems: "start", maxWidth: "300px" }}
    >
      {/* Icon  */}
      <img src={IC_CHAT} style={{ objectFit: "contain", marginTop: "8px" }} />
      <Stack>
        <Typography
          fontSize={"28px"}
          fontWeight={600}
          sx={{ fontFamily: "Sk-Modernist" }}
        >
          {props.title}
        </Typography>
        {/* Text  */}
        <Typography color="#77ABFF" fontWeight={400} fontSize={16}>
          {props.description}
        </Typography>
        {/* Email  */}
        <Typography sx={{ mt: "16px" }} fontWeight={600} fontSize={16}>
          {props.value}
        </Typography>
      </Stack>
    </Stack>
  );
}
