import React, { useEffect, useState } from "react";
import { Stack, Typography, CircularProgress } from "@mui/material";
import { useRecoilState } from "recoil";
import { Route, Routes } from "react-router";
import { enqueueSnackbar } from "notistack";
import {
  aCurrentSection,
  aCurrentSectionDescription,
  aProfile,
} from "../../core/states";
import { useModal } from "mui-modal-provider";
import SectionAdminDashboard from "./admin";
import { Role } from "../../types/Role";
import { rtmGetProfile } from "../../rtm";
import Sidebar from "../../components/Sidebar";
import SectionAdminUsers from "./admin/users";
import SectionPosts from "./posts";
import SectionSettings from "./settings";

export default function PageDashboard() {
  const [section] = useRecoilState(aCurrentSection);
  const [sectionInfo] = useRecoilState(aCurrentSectionDescription);
  const [profile, setProfile] = useRecoilState(aProfile);
  const { showModal } = useModal();
  const [connectionError, setConnectionError] = useState(false);

  async function loadProfile() {
    try {
      const prof = await rtmGetProfile();
      setProfile(prof);
    } catch (err: any) {
      console.log(err);
      enqueueSnackbar("Error loading your profile. ", { variant: "error" });
    }
  }

  useEffect(() => {
    loadProfile();
  }, []);

  return (
    <Stack sx={{ height: "100vh" }} direction={"row"}>
      {connectionError && (
        <Stack
          sx={{
            position: "absolute",
            background: "gold",
            color: "black",
            width: "100%",
          }}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing="8px"
        >
          <Typography>Connection interrupted. Reconnecting...</Typography>
          <CircularProgress size={"20px"} />
        </Stack>
      )}
      <Sidebar />
      <Stack flex={1} overflow={"auto"}>
        {section && (
          <Stack sx={{ my: "32px" }}>
            <Stack sx={{ mx: "32px" }}>
              <Typography fontSize={36} fontWeight={600}>
                {section || "Dashboard"}
              </Typography>
              <Typography fontSize={16} fontWeight={400}>
                {sectionInfo || "Welcome"}
              </Typography>
            </Stack>
          </Stack>
        )}
        {profile && profile.role === Role.Admin && (
          <Routes>
            <Route path="/users" element={<SectionAdminUsers />} />
            <Route path="/blog" element={<SectionPosts />} />
            <Route path="/settings" element={<SectionSettings />} />
            <Route path="/*" element={<SectionAdminDashboard />} />
          </Routes>
        )}
        {profile && profile.role === Role.Editor && (
          <Routes>
            <Route path="/blog" element={<SectionPosts />} />
          </Routes>
        )}
      </Stack>
    </Stack>
  );
}
